var ke = Object.defineProperty;
var te = (s) => {
  throw TypeError(s);
};
var Ie = (s, e, t) => e in s ? ke(s, e, { enumerable: !0, configurable: !0, writable: !0, value: t }) : s[e] = t;
var o = (s, e, t) => Ie(s, typeof e != "symbol" ? e + "" : e, t), se = (s, e, t) => e.has(s) || te("Cannot " + t);
var X = (s, e, t) => (se(s, e, "read from private field"), t ? t.call(s) : e.get(s)), re = (s, e, t) => e.has(s) ? te("Cannot add the same private member more than once") : e instanceof WeakSet ? e.add(s) : e.set(s, t), ne = (s, e, t, r) => (se(s, e, "write to private field"), r ? r.call(s, t) : e.set(s, t), t);
const Ne = (s, e, t) => s + (e - s) * t;
const Re = (s, e, t) => {
  const r = Math.max(s.length, e.length);
  let n = "";
  for (let i = 0; i < r; i++) {
    let l = s.charCodeAt(i);
    isNaN(l) && (l = 97);
    let c = e.charCodeAt(i);
    isNaN(c) && (c = 97), n += String.fromCharCode(l + (c - l) * t);
  }
  return n;
}, Ve = (s, e, t) => new Date(s.getTime() + (e.getTime() - s.getTime()) * t), je = (s, e) => e, qe = (s) => typeof s == "number" ? Ne : typeof s == "string" ? Re : s instanceof Date ? Ve : je;
var D;
class Y {
  /**
   * Creates a new instance of `ElementPosition`.
   * @param index - The index of the element.
   * @param total - The total number of elements in the collection.
   */
  constructor(e, t) {
    /**
     * The counter of the element starting from 1.
     */
    o(this, "counter");
    /**
     * Checks if the element is the first element in the collection.
     * @returns `true` if the element is the first element, `false` otherwise.
     */
    o(this, "isFirst");
    /**
     * Checks if the counter of the element is even.
     * @returns `true` if the counter is even, `false` otherwise.
     */
    o(this, "isEven");
    /**
     * Checks if the counter of the element is odd.
     * @returns `true` if the counter is odd, `false` otherwise.
     */
    o(this, "isOdd");
    re(this, D);
    o(this, "dispose", () => {
      this.total.dispose();
    });
    this.index = e, this.total = t, this.counter = e + 1, this.isFirst = e === 0, this.isEven = e % 2 === 1, this.isOdd = e % 2 === 0;
  }
  /**
   * Checks if the element is the last element in the collection.
   * @returns `true` if the element is the last element, `false` otherwise.
   */
  get isLast() {
    return X(this, D) == null && ne(this, D, this.total.map((e) => this.counter === e)), X(this, D);
  }
}
D = new WeakMap();
const H = class H {
  /**
   * Represents a signal with a value of type T.
   *
   * @param value - The initial value of the signal.
   * @param equals - A function that determines whether two values of type T are equal.
   * @public
   */
  constructor(e, t) {
    /**
     * @internal
     */
    o(this, "$__signal__", !0);
    /**
     * @internal
     */
    o(this, "_value");
    /**
     * @internal
     */
    o(this, "_derivatives", []);
    /**
     * @internal
     */
    o(this, "_onValueListeners", []);
    /**
     * @internal
     */
    o(this, "_onDisposeListeners", []);
    /**
     * Gets the current value of the signal.
     * @returns The current value of the signal.
     */
    o(this, "get", () => this._value);
    /**
     * Checks if the signal has any registered listeners.
     * @returns `true` if the signal has listeners, `false` otherwise.
     */
    o(this, "hasListeners", () => this._onValueListeners.length > 0);
    /**
     * Registers a listener function to be called whenever the value of the signal changes.
     * The listener function will be immediately called with the current value of the signal.
     * Returns a function that can be called to unregister the listener.
     *
     * @param listener - The listener function to be called when the value of the signal changes.
     * @param options - Options for the listener.
     */
    o(this, "on", (e, t = {}) => {
      t.skipInitial || e(this.get(), void 0);
      const r = t.once ? (i, l) => {
        n(), e(i, l);
      } : e;
      this._onValueListeners.push(r);
      const n = () => {
        this._onValueListeners.splice(
          this._onValueListeners.indexOf(r),
          1
        ), t.abortSignal != null && t.abortSignal.removeEventListener("abort", n);
      };
      return t.abortSignal != null && t.abortSignal.addEventListener("abort", n), n;
    });
    /**
     * @internal
     */
    o(this, "_setAndNotify", (e, t) => {
      const r = this._value, n = this.equals(r, e);
      n || (this._value = e), (t || !n) && this._onValueListeners.forEach((i) => i(e, r));
    });
    /**
     * @internal
     */
    o(this, "_disposed", !1);
    /**
     * Checks whether the signal is disposed.
     * @returns True if the signal is disposed, false otherwise.
     */
    o(this, "isDisposed", () => this._disposed);
    /**
     * Adds a listener function to be called when the object is disposed.
     * @param listener - The listener function to be called when the object is disposed.
     * @returns A function that can be called to remove the listener.
     */
    o(this, "onDispose", (e) => {
      this._onDisposeListeners.push(e);
    });
    /**
     * Disposes the signal, releasing any resources associated with it.
     */
    o(this, "dispose", () => {
      this._disposed || (this._disposed = !0, this._onDisposeListeners.forEach((e) => e()), this._onDisposeListeners.length = 0, this._derivatives.length = 0);
    });
    /**
     * Returns a new Computed instance that applies the given mapping function to the value of this Signal.
     * The mapping function is called whenever the value of this Signal changes.
     *
     * @typeParam O - The type of the mapped value.
     * @param fn - The mapping function to apply to the value of this Signal.
     * @param equals - Optional equality function to determine if two mapped values are equal.
     * @returns - A new Computed instance with the mapped value.
     */
    o(this, "map", (e, t = (r, n) => r === n) => {
      const r = new L(() => {
        try {
          return e(this.get());
        } catch (n) {
          throw console.error("Error in Signal.map:", n), n;
        }
      }, t);
      return this.setDerivative(r), r;
    });
    /**
     * Returns a new Signal that applies the given function to the value of the current Signal,
     * and then flattens the resulting Signal.
     *
     * @typeParam O - The type of the value emitted by the resulting Signal.
     * @param fn - The function to apply to the value of the current Signal.
     * @param equals - A function that determines whether two values of type O are equal.
     *               Defaults to a strict equality check (===).
     * @returns A new Signal that emits the values of the resulting Signal.
     */
    o(this, "flatMap", (e, t = (r, n) => r === n) => {
      const r = new L(() => {
        try {
          return e(this.get()).get();
        } catch (n) {
          throw console.error("Error in Signal.flatMap:", n), n;
        }
      }, t);
      return this.setDerivative(r), r;
    });
    /**
     * Invokes a callback function with the current value of the signal, without modifying the signal.
     *
     * @param fn - The callback function to be invoked with the current value of the signal.
     * @returns A new signal that emits the same value as the original signal and invokes the callback function.
     */
    o(this, "tap", (e) => this.map((t) => (e(t), t)));
    /**
     * Returns a new Signal that emits the value at the specified key of the current value.
     *
     * @param key - The key of the value to retrieve.
     * @returns A new Signal that emits the value at the specified key.
     */
    o(this, "at", (e) => this.map((t) => t[e]));
    /**
     * @internal
     */
    o(this, "_$");
    o(this, "filter", (e, t) => {
      let r = t ?? this.get();
      const n = new L(() => {
        try {
          const i = this.get();
          return r = e(i) ? i : r;
        } catch (i) {
          throw console.error("Error in Signal.filter:", i), i;
        }
      }, this.equals);
      return this.setDerivative(n), n;
    });
    /**
     * Returns a new Computed object that applies the provided mapping function to the value of this Signal,
     * and filters out values that are `undefined` or `null`.
     *
     * @typeParam O - The type of the mapped value.
     * @param fn - The mapping function to apply to the value of this Signal.
     * @param startValue - The initial value for the Computed object.
     * @param equals - Optional equality function to determine if two values are equal.
     * @returns - A new Computed object with the mapped and filtered values.
     */
    o(this, "filterMap", (e, t, r = (n, i) => n === i) => {
      let n = t;
      const i = new L(() => {
        try {
          const l = this.get(), c = e(l);
          return n = c ?? n;
        } catch (l) {
          throw console.error("Error in Signal.filterMap:", l), l;
        }
      }, r);
      return this.setDerivative(i), i;
    });
    /**
     * Maps the values emitted by the signal to a new value asynchronously using the provided function.
     * If the function throws an error, it will be caught and logged.
     * If a recovery function is provided, it will be called with the error and its return value will be used as the mapped value.
     * If no recovery function is provided, the error will be logged as an unhandled promise rejection.
     *
     * @typeParam O - The type of the mapped value.
     * @param fn - The function to map the values emitted by the signal. The second argument to this function allows to cancel the previously running mapping function if it has not completed by the time a new value is emitted.
     * @param alt - The alternate value to use if the signal is disposed or the mapping function throws an error.
     * @param recover - The recovery function to handle errors thrown by the mapping function.
     * @param equals - The equality function to compare the mapped values for equality.
     * @returns A property that holds the mapped value and can be observed for changes.
     */
    o(this, "mapAsync", (e, t, r, n = (i, l) => i === l) => {
      const i = v(t, n);
      let l = 0, c = new AbortController();
      return i.onDispose(
        this.on(async (a) => {
          const h = ++l;
          c.abort(), c = new AbortController();
          try {
            const u = await e(a, { abortSignal: c.signal });
            h === l && i.set(u);
          } catch (u) {
            if (h === l)
              if (r != null)
                i.set(r(u));
              else
                throw u;
          }
        })
      ), i;
    });
    /**
     * Maps the values of the signal using the provided function `fn`, and returns a new signal
     * containing the mapped values. If the mapped value is `undefined` or `null`, it is replaced
     * with the provided `alt` value.
     *
     * @typeParam O - The type of the mapped value.
     * @param fn - The function used to map the values of the signal.
     * @param alt - The alternative value to use when the mapped value is `undefined` or `null`.
     * @returns A new signal containing the mapped values.
     */
    o(this, "mapMaybe", (e, t) => this.map((r) => e(r) ?? t));
    /**
     * Feeds a property into the signal and sets up disposal behavior.
     * @param prop - The property to feed into the signal.
     * @param autoDisposeProp - Determines whether the property should be automatically disposed when the signal is disposed.
     * @returns The input property.
     */
    o(this, "feedProp", (e, t = !1) => {
      const r = this.on(e.set);
      return e.onDispose(r), t ? this.onDispose(e.dispose) : this.onDispose(r), e;
    });
    /**
     * Derives a new property from the current signal.
     * @param options - The options for the derived property.
     * @param options.autoDisposeProp - Determines whether the derived property should be automatically disposed.
     * @param options.equals - A function that determines if two values are equal.
     * @returns The derived property.
     */
    o(this, "deriveProp", ({
      autoDisposeProp: e = !0,
      equals: t
    } = {}) => this.feedProp(v(this.get(), t), e));
    /**
     * Derives a new signal from the current signal. Useful to create a new signal that emits the same values as the current signal but can be disposed independently.
     * @returns A new signal that emits the same values as the current signal.
     */
    o(this, "derive", () => this.map((e) => e));
    /**
     * Returns a signal that emits the count of values received so far.
     * @returns A signal that emits the count of values received so far.
     */
    o(this, "count", () => {
      let e = 0;
      return this.map(() => ++e);
    });
    /**
     * Adds a computed value as a derivative of the signal.
     * When the computed value is disposed, it is automatically removed from the derivatives list.
     * Additionally, when the computed value is disposed, it sets the signal as dirty.
     * @param computed - The computed value to add as a derivative.
     */
    o(this, "setDerivative", (e) => {
      this._derivatives.push(e), e.onDispose(() => {
        this._derivatives.splice(
          this._derivatives.indexOf(e),
          1
        );
      }), e.onDispose(this.on(e.setDirty)), this.onDispose(e.dispose);
    });
    this.equals = t, this._value = e;
  }
  /**
   * Gets the value of the signal.
   * @returns The current value of the signal.
   */
  get value() {
    return this._value;
  }
  /**
   * Represents a collection of signals mapping to each key/field in the wrapped value.
   * @typeParam T - The type of the signals.
   */
  get $() {
    return this._$ !== void 0 ? this._$ : this._$ = new Proxy(this, {
      get: (e, t) => this.at(t)
    });
  }
};
/**
 * Creates a Signal that holds the result of a Promise.
 *
 * @typeParam O - The type of the value returned by the Promise.
 * @param promise - The Promise to use to feed the Signal.
 * @param init - The initial value of the Signal before the Promise resolves.
 * @param recover - A function to recover from Promise rejection and provide an alternative value for the Signal.
 * @param equals - A function to compare two values of type O for equality. Defaults to strict equality (===).
 * @returns - A Signal that represents the result of the Promise.
 */
o(H, "ofPromise", (e, t, r, n = (i, l) => i === l) => {
  const i = new H(t, n);
  return e.then((l) => i._setAndNotify(l, !1)).catch((l) => {
    r != null ? i._setAndNotify(r(l), !1) : console.error(
      "Unhandled promise rejection in Signal.ofPromise:",
      l
    );
  }), i;
}), /**
 * Checks if a value is a Signal.
 *
 * @param value - The value to check.
 * @returns `true` if the value is a Signal, `false` otherwise.
 */
o(H, "is", (e) => (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  e != null && e.$__signal__ === !0
));
let d = H;
const Fe = typeof queueMicrotask == "function" ? queueMicrotask : (s) => Promise.resolve().then(s);
class L extends d {
  /**
   * Represents a Signal object.
   * @param _fn - The function that returns the value of the signal.
   * @param equals - The function used to compare two values of type T for equality.
   */
  constructor(t, r) {
    super(void 0, r);
    /**
     * @internal
     */
    o(this, "$__computed__", !0);
    /**
     * @internal
     */
    o(this, "_isDirty", !1);
    /**
     * Marks the signal as dirty, indicating that its value has changed and needs to be recalculated.
     * If the signal is already dirty or disposed, this method does nothing.
     * It also marks all dependent signals as dirty and schedules a notification to update their values.
     */
    o(this, "setDirty", () => {
      this._isDirty || this._disposed || (this._isDirty = !0, this._derivatives.forEach((t) => t.setDirty()), this._scheduleNotify());
    });
    /**
     * @internal
     */
    o(this, "_scheduleCount", 0);
    /**
     * Schedules a notification to be executed asynchronously.
     * If the signal is dirty, it will be updated and notified.
     * @internal
     */
    o(this, "_scheduleNotify", () => {
      const t = ++this._scheduleCount;
      Fe(() => {
        this._scheduleCount !== t || this._disposed !== !1 || this._isDirty && (this._isDirty = !1, this._setAndNotify(this._fn(), !1));
      });
    });
    /** {@inheritDoc Signal.get} */
    o(this, "get", () => (this._isDirty && (this._isDirty = !1, this._setAndNotify(this._fn(), !0)), this._value));
    this._fn = t, this.setDirty();
  }
  /**
   * Checks if a value is an instance of `Computed`.
   *
   * @param value - The value to check.
   * @returns `true` if the value is an instance of `Computed`, `false` otherwise.
   */
  static is(t) {
    return t != null && t.$__computed__ === !0;
  }
  /** {@inheritDoc Signal.value} */
  get value() {
    return this.get();
  }
}
const U = class U extends d {
  constructor() {
    super(...arguments);
    /**
     * @internal
     */
    o(this, "$__prop__", !0);
    /**
     * Changes the value of the property and notifies its listeners.
     *
     * @param value - The new value of the property.
     */
    o(this, "set", (t) => {
      this._setAndNotify(t, !1);
    });
    /**
     * Updates the value of the signal by applying the provided function to the current value.
     * @param fn - The function to apply to the current value.
     */
    o(this, "update", (t) => {
      this._setAndNotify(t(this.get()), !1);
    });
    /**
     * Creates a reducer function that combines the provided reducer function and effects.
     * @param fn - The reducer function that takes the current state and an action, and returns the new state.
     * @param effects - An array of effects to be executed after the state is updated.
     * @returns A dispatch function that can be used to update the state and trigger the effects.
     */
    o(this, "reducer", (t, ...r) => {
      const n = this;
      return function i(l) {
        const c = n.value;
        n.update((a) => t(a, l)), !n.equals(c, n.value) && r.forEach(
          (a) => a({
            previousState: c,
            state: n.value,
            action: l,
            dispatch: i
          })
        );
      };
    });
    /**
     * Creates an isomorphism for the Signal.
     * An isomorphism is a pair of functions that convert values between two types,
     * along with an equality function to compare values of the second type.
     *
     * @param to - A function that converts values from type T to type O.
     * @param from - A function that converts values from type O to type T.
     * @param equals - An optional function that compares values of type O for equality.
     *                Defaults to a strict equality check (===).
     * @returns A Prop object representing the isomorphism.
     */
    o(this, "iso", (t, r, n = (i, l) => i === l) => {
      const i = new U(t(this.get()), n);
      return i.onDispose(this.on((l) => i.set(t(l)))), i.on((l) => this._setAndNotify(r(l), !1)), i;
    });
    /**
     * Returns a `Prop` that represents the value at the specified key of the current value.
     *
     * @param key - The key of the value to access.
     * @returns A `Prop` that represents the value at the specified key.
     */
    o(this, "atProp", (t) => this.iso(
      (r) => r[t],
      (r) => ({ ...this.value, [t]: r })
    ));
  }
  /**
   *  Access for the current value of the property.
   */
  get value() {
    return this.get();
  }
  set value(t) {
    this._setAndNotify(t, !1);
  }
};
/**
 * Checks if a value is a Prop.
 * @param value - The value to check.
 * @returns `true` if the value is a Prop, `false` otherwise.
 */
o(U, "is", (t) => (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  t != null && t.$__prop__ === !0
));
let N = U;
const z = (s, e, t = (r, n) => r === n) => {
  const r = new L(s, t);
  return e.forEach((n) => n.setDerivative(r)), r;
}, Be = (s, e, t = {}) => {
  let r = t.once ? () => {
    i(), s();
  } : s;
  if (t.skipInitial) {
    let l = !1;
    const c = r;
    r = () => {
      l ? c() : l = !0;
    };
  }
  const n = z(r, e), i = () => {
    n.dispose(), t.abortSignal != null && t.abortSignal.removeEventListener("abort", i);
  };
  return t.abortSignal != null && t.abortSignal.addEventListener("abort", i), i;
}, v = (s, e = (t, r) => t === r) => new N(s, e), W = (s, e = (t, r) => t === r) => new d(s, e), oe = () => typeof window < "u" ? window : void 0, y = {
  /**
   * Maps a value or a Signal to a new value.
   * If the value is a Signal, it returns a new Signal with the mapped value.
   * If the value is not a Signal, it returns the mapped value.
   *
   * @typeParam T - The type of the value.
   * @typeParam U - The type of the new value.
   * @param value - The value or Signal to map.
   * @param fn - The function to map the value.
   * @returns The mapped value.
   */
  map: (s, e) => d.is(s) ? s.map(e) : e(s),
  /**
   * Wraps a value or a Signal instance into a Signal.
   * If the value is already a Signal, it returns the value itself.
   * If the value is not a Signal, it creates a new Signal instance with the given value.
   *
   * @typeParam O - The type of the value.
   * @param value - The value or Signal instance to wrap.
   * @param equals - A function that determines if two values are equal. Defaults to strict equality (===).
   * @returns A Signal instance.
   */
  toSignal: (s, e) => d.is(s) ? s : W(s, e),
  /**
   * Wraps a value in a `Signal` if it is not already a `Signal`.
   * If the value is `null` or `undefined`, it returns `null` or `undefined` respectively.
   * @param value - The value to wrap or check.
   * @returns The wrapped value if it is not `null` or `undefined`, otherwise `null` or `undefined`.
   */
  maybeToSignal: (s, e) => {
    if (s != null)
      return y.toSignal(s, e);
  },
  /**
   * Gets the value from a `Signal` or the value itself if it is not a `Signal`.
   * @param value - The value or Signal instance to get the value from.
   * @returns The value.
   */
  get: (s) => d.is(s) ? s.get() : s,
  /**
   * Adds a listener to a `Signal` or calls the listener immediately if it is not a `Signal`.
   * @param value - The value or Signal instance to add the listener to.
   * @param listener - The listener to call when the value changes.
   * @returns A function to remove the listener.
   */
  on: (s, e) => d.is(s) ? s.on(e) : (e(s), () => {
  }),
  /**
   * Disposes of a value or a Signal.
   * If the value is a Signal, it disposes of the Signal.
   * If the value is not a Signal, it does nothing.
   * @param value - The value or Signal instance to dispose of.
   */
  dispose: (s) => {
    d.is(s) && s.dispose();
  },
  /**
   * Derives a Prop from a Signal.
   * If the value is a Signal, it returns a new Prop with the derived value.
   * If the value is not a Signal, it returns a new Prop with the value.
   * @param value - The value or Signal instance to derive the Prop from.
   * @param options - The options for the derived Prop.
   * @param options.autoDisposeProp - Determines whether the derived Prop should be automatically disposed.
   * @param options.equals - A function that determines if two values are equal.
   * @returns A Prop instance.
   */
  deriveProp: (s, {
    autoDisposeProp: e = !0,
    equals: t
  } = {}) => d.is(s) ? s.deriveProp({ autoDisposeProp: e, equals: t }) : v(s, t)
}, Ht = (...s) => (e, t) => {
  const r = s.filter((n) => d.is(n));
  return z(
    () => e(...s.map((n) => y.get(n))),
    r,
    t
  );
}, $t = (...s) => (e, t = {}) => {
  const r = s.filter((n) => d.is(n));
  return Be(
    () => e(...s.map(y.get)),
    r,
    t
  );
};
class le {
  constructor() {
    o(this, "_store", /* @__PURE__ */ new Map());
    /**
     * Retrieves the value associated with the specified key from the memory store.
     * @param key - The key to retrieve the value for.
     * @returns The value associated with the key, or `null` if the key is not found.
     */
    o(this, "getItem", (e) => this._store.get(e) ?? null);
    /**
     * Sets the value associated with the specified key in the memory store.
     * @param key - The key to set the value for.
     * @param value - The value to set.
     */
    o(this, "setItem", (e, t) => {
      this._store.set(e, t);
    });
  }
}
const ce = ({
  key: s,
  defaultValue: e,
  store: t,
  serialize: r = JSON.stringify,
  deserialize: n = JSON.parse,
  equals: i = (c, a) => c === a,
  onLoad: l = (c) => c
}) => {
  const c = t.getItem(s), a = new N(
    c != null ? l(n(c)) : typeof e == "function" ? e() : e,
    i
  );
  return a.on((h) => {
    t.setItem(s, r(h));
  }), a;
}, kt = (s) => {
  var e;
  return ce({
    ...s,
    store: ((e = oe()) == null ? void 0 : e.localStorage) ?? new le()
  });
}, It = (s) => {
  var e;
  return ce({
    ...s,
    store: ((e = oe()) == null ? void 0 : e.sessionStorage) ?? new le()
  });
};
function ie(s) {
  return typeof requestAnimationFrame == "function" ? requestAnimationFrame(s) : setTimeout(s, 0);
}
const Ue = (s, e, t, r) => {
  const n = (r == null ? void 0 : r.duration) ?? 300, i = (r == null ? void 0 : r.easing) ?? ((A) => A), l = (r == null ? void 0 : r.equals) ?? ((A, I) => A === I);
  let c = r == null ? void 0 : r.interpolate, a = s, h = e(), u = performance.now(), m = null, T = !0;
  const p = new L(e, l), g = v(s, l);
  g.onDispose(() => {
    m !== null && cancelAnimationFrame(m);
  }), g.onDispose(p.dispose), t.forEach((A) => {
    A.setDerivative(p), A.onDispose(g.dispose);
  });
  const w = (A) => {
    h = A, u = performance.now(), a = g.value, T && (T = !1, m = ie(K));
  }, K = () => {
    const I = (performance.now() - u) / y.get(n), $e = i(I);
    c == null && (c = qe(a));
    let ee = c(a, h, $e);
    I >= 1 ? (T = !0, ee = h) : m = ie(K), g.set(ee);
  };
  return p.on(w), g;
}, Nt = (s, e) => {
  const { initialValue: t, ...r } = e ?? {};
  return Ue(
    t ?? s.get(),
    s.get,
    [s],
    r
  );
}, Rt = (s, e) => {
  const { signals: t, literals: r } = Object.entries(s).reduce(
    ({ signals: i, literals: l }, [c, a]) => (d.is(a) ? i.push([c, a]) : l[c] = a, { signals: i, literals: l }),
    { signals: [], literals: {} }
  ), n = t.map(([, i]) => i);
  return z(() => (t.forEach(([i, l]) => r[i] = l.value), e(r)), n);
}, ae = /* @__PURE__ */ new Set(["checked", "disabled", "hidden"]), ue = /* @__PURE__ */ new Set(["selected"]), he = /* @__PURE__ */ new Set([
  "rowSpan",
  "colSpan",
  "tabIndex",
  "valueAsNumber"
]), fe = /* @__PURE__ */ new Set(["valueAsDate"]), de = /* @__PURE__ */ new Set([
  "value",
  "textContent",
  "innerText",
  "innerHTML",
  "outerHTML",
  "className",
  "classList"
]), We = (s, e) => ue.has(s) ? (t) => {
  t == null || t !== !0 ? e.removeAttribute(s) : e.setAttribute(s, "");
} : ae.has(s) ? (t) => {
  t == null ? e[s] = null : e[s] = !!t;
} : he.has(s) ? (t) => {
  t == null ? e[s] = null : e[s] = Number(t);
} : fe.has(s) ? (t) => {
  t == null ? e[s] = null : e[s] = t;
} : de.has(s) ? (t) => {
  t == null ? e[s] = null : e[s] = String(t);
} : (t) => {
  t == null ? e.removeAttribute(s) : e.setAttribute(s, t);
}, Je = (s, e) => ue.has(s) ? () => e.hasAttribute(s) : ae.has(s) ? () => !!e[s] : he.has(s) ? () => Number(e[s]) : fe.has(s) ? () => e[s] : de.has(s) ? () => String(e[s]) : () => e.getAttribute(s), R = (s) => {
  const e = s;
  e && e.onblur && (e.onblur = null), !(!s || s.ownerDocument === void 0) && s.parentElement && s.parentElement.removeChild(s);
}, Ge = (s) => pe(s) ? s : s.parentElement, pe = (s) => s.nodeType === 1;
class ge extends Error {
  constructor(e) {
    super(`Provider not found: ${e.description}`);
  }
}
class x {
  /**
   * Constructs a new `DOMContext` instance.
   *
   * @param document - The `Document` instance associated with this context.
   * @param element - The `Element` instance associated with this context.
   * @param reference - An optional `Node` instance that serves as a reference for this context.
   * @param providers - The `Providers` instance associated with this context.
   * @param isFirstLevel - A boolean value indicating whether this context is at the first level, meaning the outermost node in the generated DOM.
   */
  constructor(e, t, r, n) {
    /**
     * Creates a new DOM element (eg: HTML or SVG) with the specified tag name and namespace.
     *
     * @param tagName - The tag name of the element to create.
     * @param namespace - The namespace URI to create the element in, or `undefined` to create a standard HTML element.
     * @returns The newly created element.
     */
    o(this, "createElement", (e, t) => t !== void 0 ? this.document.createElementNS(t, e) : this.document.createElement(e));
    /**
     * Creates a new DOM element (eg: HTML or SVG) with the specified tag name and namespace and appends it to the current element.
     *
     * @param tagName - The tag name of the element to create.
     * @param namespace - The namespace URI to create the element in, or `undefined` to create a standard HTML element.
     * @returns The newly created element.
     */
    o(this, "makeChildElement", (e, t) => {
      const r = this.createElement(e, t);
      return this.appendOrInsert(r), this.withElement(r);
    });
    /**
     * Creates a new text node with the specified text content.
     * @param text - The text content for the new text node.
     * @returns A new `Text` node with the specified text content.
     */
    o(this, "createText", (e) => this.document.createTextNode(e));
    /**
     * Creates a new text node with the specified text content and appends it to the current element.
     * @param text - The text content for the new text node.
     * @returns A new `DOMContext` with a reference to the new text node.
     */
    o(this, "makeChildText", (e) => {
      const t = this.createText(e);
      return this.appendOrInsert(t), this.withReference(t);
    });
    /**
     * Sets the text content of the current element.
     * @param text - The text content to set.
     */
    o(this, "setText", (e) => {
      this.reference.nodeValue = e;
    });
    /**
     * Gets the text content of the current element or text node.
     * @returns The text content of the current element or text node.
     */
    o(this, "getText", () => {
      var e;
      return ((e = this.reference) == null ? void 0 : e.nodeValue) ?? this.element.textContent ?? "";
    });
    /**
     * Creates a new `DOMContext` with a reference to a newly created text node.
     * The text node is appended or inserted to the current `DOMContext`.
     * The new `DOMContext` with the reference is returned.
     */
    o(this, "makeRef", () => {
      const e = this.createText("");
      return this.appendOrInsert(e), this.withReference(e);
    });
    /**
     * Appends or inserts a child node to the element, depending on whether a reference node is provided.
     *
     * @param child - The child node to append or insert.
     */
    o(this, "appendOrInsert", (e) => {
      this.reference === void 0 ? this.element.appendChild(e) : this.element.insertBefore(e, this.reference);
    });
    /**
     * Creates a new `DOMContext` instance with the provided `element`.
     * @param element - The DOM element to use in the new `DOMContext` instance.
     * @returns A new `DOMContext` instance with the provided `element`.
     */
    o(this, "withElement", (e) => new x(this.document, e, void 0, this.providers));
    /**
     * Creates a new `DOMContext` instance with a reference to a DOM element selected by the provided `selector`.
     * @param selector - The CSS selector for the target DOM element.
     * @returns A new `DOMContext` instance with a reference to the selected DOM element.
     */
    o(this, "makePortal", (e) => {
      const t = this.document.querySelector(e);
      if (t == null)
        throw new Error(`Cannot find element by selector for portal: ${e}`);
      return this.withElement(t);
    });
    /**
     * Creates a new `DOMContext` instance with the specified reference.
     *
     * @param reference - The optional `Text` node to use as the reference for the new `DOMContext`.
     * @returns A new `DOMContext` instance with the specified reference.
     */
    o(this, "withReference", (e) => new x(this.document, this.element, e, this.providers));
    /**
     * Returns a new HTMLDOMContext instance with the specified providers merged into
     * the existing providers.
     *
     * @param providers - An object containing the providers to be merged into the existing providers.
     * @returns A new HTMLDOMContext instance with the merged providers.
     */
    o(this, "withProviders", (e) => new x(this.document, this.element, this.reference, {
      ...this.providers,
      ...e
    }));
    /**
     * Retrieves a provider for the given provider mark.
     *
     * @param mark - The provider mark to retrieve the provider for.
     * @returns The provider for the given mark.
     * @throws Throws `ProviderNotFoundError` if the provider for the given mark is not found.
     */
    o(this, "getProvider", (e) => {
      if (this.providers[e] === void 0)
        throw new ge(e);
      return this.providers[e];
    });
    o(this, "clear", (e) => {
      e && (this.reference !== void 0 ? R(this.reference) : R(this.element));
    });
    /**
     * Adds classes to the element.
     * @param tokens - The class names to add.
     */
    o(this, "addClasses", (e) => {
      this.element.classList.add(...e);
    });
    /**
     * Removes classes from the element.
     * @param tokens - The class names to remove.
     */
    o(this, "removeClasses", (e) => {
      this.element.classList.remove(...e);
    });
    /**
     * Gets the classes of the element.
     * @returns The classes of the element.
     */
    o(this, "getClasses", () => Array.from(this.element.classList));
    /**
     * Adds an event listener to the element.
     * @param event - The event to listen for.
     * @param listener - The listener to call when the event occurs.
     * @param options - The options for the event listener.
     * @returns A function to remove the event listener.
     */
    o(this, "on", (e, t, r) => (this.element.addEventListener(e, t, r), (n) => {
      n && this.element.removeEventListener(
        e,
        t,
        r
      );
    }));
    /**
     * Returns `true` if the context is a browser DOM context.
     * @returns `true` if the context is a browser DOM context.
     */
    o(this, "isBrowserDOM", () => !0);
    /**
     * Returns `true` if the context is a headless DOM context.
     * @returns `true` if the context is a headless DOM context.
     */
    o(this, "isHeadlessDOM", () => !1);
    /**
     * Sets the style of the element.
     * @param name - The name of the style to set.
     * @param value - The value of the style to set.
     */
    o(this, "setStyle", (e, t) => {
      this.element.style[e] = t;
    });
    /**
     * Gets the style of the element.
     * @param name - The name of the style to get.
     * @returns The value of the style.
     */
    o(this, "getStyle", (e) => this.element.style[e]);
    o(this, "makeAccessors", (e) => ({
      get: Je(e, this.element),
      set: We(e, this.element)
    }));
    this.document = e, this.element = t, this.reference = r, this.providers = n;
  }
  /**
   * Creates a new `DOMContext` instance for the given `Element` and optional reference `Node`.
   *
   * @param element - The `HTMLElement` to create the `DOMContext` for.
   * @param ref - An optional reference `Node` to associate with the `DOMContext`.
   * @returns A new `DOMContext` instance.
   */
  static of(e, t) {
    return new x(e.ownerDocument, e, t, {});
  }
}
const Q = (s, e) => {
  const t = s(e);
  return (r = !0) => t(r);
}, Vt = (s, e, { doc: t, clear: r, disposeWithParent: n = !0 } = {}) => {
  const i = typeof e == "string" ? (t ?? document).querySelector(e) : e;
  if (i === null)
    throw new Xe(
      `Cannot find element by selector for render: ${e}`
    );
  r !== !1 && (t ?? i.ownerDocument) != null && i.nodeType === 1 && (i.innerHTML = "");
  const l = Ge(i), c = pe(i) ? void 0 : i, a = x.of(l, c), h = Q(s, a);
  let u;
  return n && (u = new MutationObserver((m) => {
    var T;
    (T = m[0]) == null || T.removedNodes.forEach((p) => {
      p === i && (h(i.nodeType !== Node.ELEMENT_NODE), u == null || u.disconnect());
    });
  }), u.observe(i.parentElement, {
    childList: !0,
    subtree: !1,
    attributes: !1
  })), () => {
    u == null || u.disconnect(), h(!0);
  };
}, jt = (s, {
  startUrl: e = "https://example.com",
  selector: t = ":root"
} = {}) => {
  const r = y.toSignal(e).deriveProp(), n = new Se(t, void 0), i = new O(n, void 0, { currentURL: r }, {});
  return {
    clear: Q(s(), i),
    root: n,
    currentURL: r
  };
};
class Xe extends Error {
  constructor(e) {
    super(e);
  }
}
const me = "data-tts-node", V = "data-tts-class", j = "data-tts-style", q = "data-tts-html", F = "data-tts-text", B = "data-tts-attrs";
class qt {
  constructor({
    select: e,
    getAttribute: t,
    setAttribute: r,
    getClass: n,
    setClass: i,
    getStyles: l,
    setStyles: c,
    appendHTML: a,
    getInnerHTML: h,
    setInnerHTML: u,
    getInnerText: m,
    setInnerText: T
  }) {
    /**
     * Selects elements from the headless environment.
     * @param selector - The selector to select elements from. The supported selectors are CSS selectors whose complexity depends on the adapter implementation.
     * @returns An array of elements.
     */
    o(this, "select");
    /**
     * Gets the value of an attribute from an element.
     * @param el - The element to get the attribute from.
     * @param attr - The attribute to get the value from.
     * @returns The value of the attribute or null if the attribute is not set.
     */
    o(this, "getAttribute");
    /**
     * Sets the value of an attribute on an element.
     * @param el - The element to set the attribute on.
     * @param attr - The attribute to set the value of.
     * @param value - The value to set the attribute to.
     */
    o(this, "setAttribute");
    /**
     * Gets the class of an element.
     * @param el - The element to get the class from.
     * @returns The class of the element or an empty string if the class is not set.
     */
    o(this, "getClass");
    /**
     * Sets the class of an element.
     * @param el - The element to set the class on.
     * @param cls - The class to set.
     */
    o(this, "setClass");
    /**
     * Gets the styles of an element.
     * @param el - The element to get the styles from.
     * @returns The styles of the element.
     */
    o(this, "getStyles");
    /**
     * Sets the styles of an element.
     * @param el - The element to set the styles on.
     */
    o(this, "setStyles");
    /**
     * Appends HTML to an element.
     * @param el - The element to append the HTML to.
     * @param html - The HTML to append.
     */
    o(this, "appendHTML");
    /**
     * Gets the inner HTML of an element.
     * @param el - The element to get the inner HTML from.
     * @returns The inner HTML of the element or an empty string if the inner HTML is not set.
     */
    o(this, "getInnerHTML");
    /**
     * Sets the inner HTML of an element.
     * @param el - The element to set the inner HTML on.
     * @param html - The inner HTML to set.
     */
    o(this, "setInnerHTML");
    /**
     * Gets the inner text of an element.
     * @param el - The element to get the inner text from.
     * @returns The inner text of the element or an empty string if the inner text is not set.
     */
    o(this, "getInnerText");
    /**
     * Sets the inner text of an element.
     * @param el - The element to set the inner text on.
     * @param text - The inner text to set.
     */
    o(this, "setInnerText");
    /**
     * Sets the content of the root element from a HeadlessPortal. Generally this will be the same instance that is
     * returned by `runHeadless`.
     *
     * @param root - The HeadlessPortal containing the content to set.
     * @param setPlaceholders - Whether to set placeholders for the content. This allows you to restore the original content
     * when you render on the server and then hydrate on the client.
     */
    o(this, "setFromRoot", (e, t) => {
      e.getPortals().forEach((n) => {
        for (const i of this.select(n.selector)) {
          if (i == null)
            throw new Error(
              `Cannot find element by selector for render: ${n.selector}`
            );
          if (n.hasChildren() && this.appendHTML(i, n.contentToHTML(t)), n.hasInnerHTML()) {
            if (t) {
              const l = this.getInnerHTML(i);
              l != null && this.setAttribute(i, q, l);
            }
            this.setInnerHTML(i, n.getInnerHTML());
          }
          if (n.hasInnerText()) {
            if (t) {
              const l = this.getInnerText(i);
              l != null && this.setAttribute(i, F, l);
            }
            this.setInnerText(i, n.getInnerText());
          }
          if (n.hasClasses()) {
            if (t) {
              const l = this.getClass(i);
              l != null && this.setAttribute(i, V, l);
            }
            this.setClass(i, n.getClasses().join(" "));
          }
          if (n.hasStyles()) {
            if (t) {
              const l = this.getStyles(i);
              Object.keys(l).length > 0 && this.setAttribute(
                i,
                j,
                JSON.stringify(l)
              );
            }
            this.setStyles(i, n.getStyles());
          }
          if (n.hasAttributes()) {
            const l = n.getAttributes();
            if (t) {
              const c = [];
              l.forEach(([a]) => {
                const h = this.getAttribute(i, a);
                h != null && c.push([a, h]);
              }), c.length > 0 && this.setAttribute(
                i,
                B,
                JSON.stringify(Object.fromEntries(c))
              );
            }
            l.forEach(([c, a]) => {
              this.setAttribute(i, c, a);
            });
          }
        }
      });
    });
    this.select = e, this.getAttribute = t, this.setAttribute = r, this.getClass = n, this.setClass = i, this.getStyles = l, this.setStyles = c, this.appendHTML = a, this.getInnerHTML = h, this.setInnerHTML = u, this.getInnerText = m, this.setInnerText = T;
  }
}
const Ye = () => {
  document.querySelectorAll(`[${me}]`).forEach(R);
}, ze = (s) => {
  const e = s.getAttribute(V);
  s.removeAttribute(V), e != null && s.setAttribute("class", e);
}, Qe = () => {
  document.querySelectorAll(`[${V}]`).forEach((e) => ze(e));
}, Ze = (s) => {
  const e = s.getAttribute(q);
  s.removeAttribute(q), e != null && (s.innerHTML = e);
}, Ke = () => {
  document.querySelectorAll(`[${q}]`).forEach((e) => Ze(e));
}, et = (s) => {
  const e = s.getAttribute(F);
  s.removeAttribute(F), e != null && (s.innerText = e);
}, tt = () => {
  document.querySelectorAll(`[${F}]`).forEach((e) => et(e));
}, ye = (s) => JSON.parse(s.replace(/&quot;/g, '"')), st = (s) => {
  const e = s.getAttribute(j);
  if (s.removeAttribute(j), e != null) {
    const t = ye(e);
    Object.entries(t).forEach(([r, n]) => {
      s.style.setProperty(r, n);
    });
  }
}, rt = () => {
  document.querySelectorAll(`[${j}]`).forEach((e) => st(e));
}, nt = (s) => {
  const e = s.getAttribute(B);
  if (s.removeAttribute(B), e != null) {
    const t = ye(e);
    Object.entries(t).forEach(([r, n]) => {
      n == null ? s.removeAttribute(r) : s.setAttribute(r, n);
    });
  }
}, it = () => {
  document.querySelectorAll(`[${B}]`).forEach((e) => nt(e));
}, Ft = () => {
  Ye(), Qe(), tt(), Ke(), rt(), it();
}, b = Symbol("class"), _ = Symbol("style"), C = Symbol("handler"), Te = () => Math.random().toString(36).substring(2, 15), ot = (s) => s.replace(/<[^>]*>?/g, "");
class be {
  constructor(e) {
    o(this, "id", Te());
    o(this, "properties", {});
    o(this, "children", []);
    o(this, "isElement", () => !0);
    o(this, "isText", () => !1);
    o(this, "getText", () => this.properties.innerText != null ? this.properties.innerText : this.properties.innerHTML != null ? ot(this.properties.innerHTML) : this.children.map((e) => e.getText()).join(""));
    o(this, "removeChild", (e) => {
      const t = this.children.indexOf(e);
      t !== -1 && this.children.splice(t, 1);
    });
    o(this, "remove", () => {
      if (this.parent != null)
        this.parent.removeChild(this);
      else
        throw new Error("Parent is undefined");
    });
    o(this, "getPortals", () => {
      const e = this.elements().flatMap((t) => t.isPortal() ? [t, ...t.getPortals()] : t.getPortals());
      return this.isPortal() && e.unshift(this), e;
    });
    o(this, "elements", () => this.children.filter((e) => e.isElement()));
    o(this, "hasInnerHTML", () => this.properties.innerHTML != null);
    o(this, "getInnerHTML", () => this.properties.innerHTML ?? "");
    o(this, "getInnerText", () => this.properties.innerText ?? "");
    o(this, "hasInnerText", () => this.properties.innerText != null);
    o(this, "hasChildren", () => this.children.length > 0);
    o(this, "hasClasses", () => this.properties[b] != null);
    o(this, "hasStyles", () => this.properties[_] != null);
    o(this, "hasAttributes", () => Object.keys(this.properties).length > 0);
    o(this, "hasHandlers", () => this.properties[C] != null);
    o(this, "hasRenderableProperties", () => this.hasClasses() || this.hasAttributes() || this.hasStyles());
    o(this, "getById", (e) => {
      if (this.properties.id === e)
        return this;
      for (const t of this.elements()) {
        const r = t.getById(e);
        if (r != null)
          return r;
      }
    });
    o(this, "trigger", (e, t) => {
      ((this.properties[C] ?? {})[e] ?? []).forEach((n) => n(t));
    });
    o(this, "click", () => {
      this.trigger("click", {});
    });
    o(this, "on", (e, t, r) => {
      var c;
      const n = (c = this.properties)[C] ?? (c[C] = {}), i = r != null && r.once ? (a) => {
        l(), t(a);
      } : (a) => t(a);
      n[e] = [...n[e] ?? [], i];
      const l = () => {
        const a = n[e] ?? [], h = a.indexOf(i);
        h !== -1 && (a.splice(h, 1), a.length === 0 ? (delete n[e], Object.keys(n).length === 0 && delete this.properties[C]) : n[e] = a, (r == null ? void 0 : r.signal) != null && r.signal.removeEventListener("abort", l));
      };
      return (r == null ? void 0 : r.signal) != null && r.signal.addEventListener("abort", l), l;
    });
    o(this, "addClasses", (e) => {
      var r;
      if (e.length === 0)
        return;
      const t = (r = this.properties)[b] ?? (r[b] = []);
      e.forEach((n) => {
        t.includes(n) || t.push(n);
      });
    });
    o(this, "removeClasses", (e) => {
      var r;
      if (e.length === 0)
        return;
      const t = (r = this.properties)[b] ?? (r[b] = []);
      e.forEach((n) => {
        const i = t.indexOf(n);
        i !== -1 && t.splice(i, 1);
      }), t.length === 0 && delete this.properties[b];
    });
    o(this, "getClasses", () => this.properties[b] ?? []);
    o(this, "getAttributes", () => Object.entries(this.properties).filter(
      ([e]) => !["innerText", "innerHTML"].includes(e)
    ));
    o(this, "getVisibleAttributes", () => Reflect.ownKeys(this.properties).flatMap(
      (e) => e === b ? [["class", this.getClasses()]] : e === _ ? [["style", this.getStyles()]] : typeof e == "string" ? [[e, String(this.properties[e])]] : []
    ));
    o(this, "setStyle", (e, t) => {
      var n;
      const r = (n = this.properties)[_] ?? (n[_] = {});
      r[e] = t, t === "" && (delete r[e], Object.keys(r).length === 0 && delete this.properties[_]);
    });
    o(this, "getStyle", (e) => {
      var t;
      return ((t = this.properties[_]) == null ? void 0 : t[e]) ?? "";
    });
    o(this, "getStyles", () => this.properties[_] ?? {});
    o(this, "makeAccessors", (e) => {
      const t = this.properties;
      return {
        get: () => t[e],
        set: (r) => t[e] = r
      };
    });
    this.parent = e;
  }
}
const lt = (s) => s.replace(/"/g, "&quot;"), ct = (s) => s.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;");
class at extends be {
  constructor(t, r, n) {
    super(n);
    o(this, "isPortal", () => !1);
    o(this, "toHTML", (t = !1) => {
      const r = this.children.map((a) => a.toHTML()).join(""), n = this.namespace ? ` xmlns="${this.namespace}"` : "";
      let i = null;
      const l = this.getVisibleAttributes().map(([a, h]) => a === "class" ? ` class="${h.join(" ")}"` : a === "style" ? typeof h == "string" ? ` style="${h}"` : ` style="${Object.entries(h).map(([u, m]) => `${u}: ${m};`).join(" ")}"` : ht.has(a) ? ` ${a}` : a === "innerHTML" ? (i = h, "") : a === "innerText" ? (i = ct(h), "") : ` ${a}="${lt(h)}"`).join(""), c = t ? ` ${me}` : "";
      return ft.has(this.tagName) && r === "" ? `<${this.tagName}${n}${l}${c} />` : `<${this.tagName}${n}${l}${c}>${i ?? r}</${this.tagName}>`;
    });
    this.tagName = t, this.namespace = r;
  }
}
class Se extends be {
  constructor(t, r) {
    super(r);
    o(this, "isPortal", () => !0);
    o(this, "toHTML", () => "");
    o(this, "contentToHTML", (t = !1) => this.children.map((r) => r.toHTML(t)).join(""));
    this.selector = t;
  }
}
class ut {
  constructor(e) {
    o(this, "id", Te());
    o(this, "isElement", () => !1);
    o(this, "isText", () => !0);
    o(this, "getText", () => this.text);
    o(this, "toHTML", () => this.text);
    this.text = e;
  }
}
class O {
  constructor(e, t, r, n) {
    o(this, "appendOrInsert", (e) => {
      if (this.reference != null) {
        const t = this.element.children.indexOf(this.reference);
        this.element.children.splice(t, 0, e);
      } else
        this.element.children.push(e);
    });
    o(this, "makeChildElement", (e, t) => {
      const r = new at(e, t, this.element);
      return this.appendOrInsert(r), new O(
        r,
        void 0,
        this.container,
        this.providers
      );
    });
    o(this, "makeChildText", (e) => {
      const t = new ut(e);
      return this.appendOrInsert(t), new O(
        this.element,
        t,
        this.container,
        this.providers
      );
    });
    o(this, "setText", (e) => {
      this.reference && this.reference.isText() && (this.reference.text = e);
    });
    o(this, "getText", () => {
      var e;
      return ((e = this.reference) == null ? void 0 : e.getText()) ?? this.element.getText();
    });
    o(this, "makeRef", () => this.makeChildText(""));
    o(this, "makePortal", (e) => {
      const t = new Se(e, this.element);
      return this.appendOrInsert(t), new O(
        t,
        void 0,
        this.container,
        this.providers
      );
    });
    o(this, "withProviders", (e) => new O(this.element, this.reference, this.container, {
      ...this.providers,
      ...e
    }));
    o(this, "getProvider", (e) => {
      if (this.providers[e] === void 0)
        throw new ge(e);
      return this.providers[e];
    });
    o(this, "clear", (e) => {
      e && (this.reference !== void 0 ? this.element.removeChild(this.reference) : this.element.remove());
    });
    o(this, "on", (e, t) => this.element.on(e, t));
    o(this, "addClasses", (e) => this.element.addClasses(e));
    o(this, "removeClasses", (e) => this.element.removeClasses(e));
    o(this, "getClasses", () => this.element.getClasses());
    o(this, "isBrowserDOM", () => !1);
    o(this, "isHeadlessDOM", () => !0);
    o(this, "setStyle", (e, t) => this.element.setStyle(e, t));
    o(this, "getStyle", (e) => this.element.getStyle(e));
    o(this, "makeAccessors", (e) => this.element.makeAccessors(e));
    this.element = e, this.reference = t, this.container = r, this.providers = n;
  }
}
const ht = /* @__PURE__ */ new Set([
  "checked",
  "disabled",
  "multiple",
  "readonly",
  "required",
  "selected"
]), ft = /* @__PURE__ */ new Set(["img", "br", "hr", "input", "link", "meta"]), Ae = (s) => (e) => {
  const t = e.makeChildText(s);
  return (r) => t.clear(r);
}, Ee = (s) => (e) => {
  const t = e.makeChildText(s.value), r = s.on((n) => t.setText(n));
  return (n) => {
    r(), t.clear(n);
  };
}, Bt = (s) => d.is(s) ? Ee(s) : Ae(s), S = (...s) => (e) => {
  const t = s.map((r) => f(r)(e));
  return (r) => {
    t.forEach((n) => n(r));
  };
}, E = () => () => {
}, dt = (s) => (e) => (e.addClasses(s), (t) => {
  t && e.removeClasses(s);
}), pt = (s) => (e) => {
  let t = [];
  const r = s.on((n) => {
    e.removeClasses(t), t = (n ?? "").split(" ").filter((i) => i.length > 0), e.addClasses(t);
  });
  return (n) => {
    r(), n && e.removeClasses(t), t.length = 0;
  };
}, $ = (s, e) => (t) => {
  const { get: r, set: n } = t.makeAccessors(s), i = r();
  return n(e), (l) => {
    l && n(i);
  };
}, k = (s, e) => (t) => {
  const { get: r, set: n } = t.makeAccessors(s), i = r(), l = e.on(n);
  return (c) => {
    l(), c && n(i);
  };
}, M = new Proxy(
  {},
  {
    /**
     * Creates a renderable component for the specified attribute.
     *
     * Generally using multiple attributes with the same name is not recommended.
     * `class` is the exception and can be used multiple times.
     *
     * @param _ - The target object.
     * @param name - The name of the attribute.
     * @returns The renderable component for the specified attribute.
     *
     */
    get: (s, e) => e === "class" ? (t) => d.is(t) ? pt(t) : dt(
      (t ?? "").split(" ").filter((r) => r.length > 0)
    ) : (t) => d.is(t) ? k(
      e,
      t
    ) : $(
      e,
      t
    )
  }
), Ut = new Proxy(
  {},
  {
    /**
     * Creates a renderable component for the specified `data-?` attribute.
     *
     * @param _ - The target object.
     * @param name - The name of the data attribute.
     * @returns The renderable component for the specified attribute.
     *
     */
    get: (s, e) => (t) => d.is(t) ? k(
      `data-${e}`,
      t
    ) : $(`data-${e}`, t)
  }
), Wt = new Proxy(
  {},
  {
    /**
     * Creates a renderable component for the specified `aria-?` attribute.
     *
     * @param _ - The target object.
     * @param name - The name of the aria attribute.
     * @returns The renderable component for the specified attribute.
     *
     */
    get: (s, e) => (t) => d.is(t) ? k(
      `aria-${e}`,
      t
    ) : $(
      `aria-${e}`,
      t
    )
  }
), Jt = new Proxy(
  {},
  {
    /**
     * Creates a renderable component for the specified `svg` attribute.
     *
     * @param _ - The target object.
     * @param name - The name of the SVG attribute.
     * @returns The renderable component for the specified attribute.
     *
     */
    get: (s, e) => (t) => d.is(t) ? k(
      e,
      t
    ) : $(
      e,
      t
    )
  }
), Gt = new Proxy(
  {},
  {
    /**
     * Creates a renderable component for the specified `math` attribute.
     *
     * @param name - The name of the Math attribute.
     * @returns The renderable component for the specified attribute.
     *
     */
    get: (s, e) => (t) => d.is(t) ? k(
      e,
      t
    ) : $(
      e,
      t
    )
  }
), f = (s) => {
  if (s == null)
    return E;
  if (Array.isArray(s))
    return S(...s.map(f));
  if (typeof s == "string")
    return Ae(s);
  if (d.is(s))
    return Ee(s);
  if (typeof s == "function")
    return s;
  throw new Error(`Unknown type: '${typeof s}' for child: ${s}`);
}, _e = (s, ...e) => (t) => {
  const r = t.makeChildElement(s, void 0), n = e.map((i) => f(i)(r));
  return (i) => {
    n.forEach((l) => l(!1)), r.clear(i);
  };
}, ve = (s, e, ...t) => (r) => {
  const n = r.makeChildElement(s, e), i = t.map((l) => f(l)(n));
  return (l) => {
    i.forEach((c) => c(!1)), n.clear(l);
  };
}, Xt = new Proxy(
  {},
  {
    /**
     * Creates a renderable that represents an HTML element.
     * @param tagName - The HTML tag name.
     * @returns A renderable function that creates and appends the HTML element to the DOM.
     */
    get: (s, e) => (...t) => _e(e, t.flatMap(f))
  }
), Yt = new Proxy(
  {},
  {
    /**
     * Creates a renderable that represents an HTMLInput element.
     * @param type - The input type name.
     * @returns A renderable function that creates and appends the HTMLInput element to the DOM.
     */
    get: (s, e) => (...t) => _e("input", M.type(e), ...t)
  }
), gt = "http://www.w3.org/2000/svg", zt = new Proxy(
  {},
  {
    /**
     * Creates a renderable that represents an SVG element.
     * @param tagName - The SVG tag name.
     * @returns A renderable function that creates and appends the SVG element to the DOM.
     */
    get: (s, e) => (...t) => ve(e, gt, t.flatMap(f))
  }
), mt = "http://www.w3.org/1998/Math/MathML", Qt = new Proxy(
  {},
  {
    /**
     * Creates a renderable that represents an Math element.
     * @param tagName - The Math tag name.
     * @returns A renderable function that creates and appends the Math element to the DOM.
     */
    get: (s, e) => (...t) => ve(e, mt, t.flatMap(f))
  }
), we = (s, e) => {
  if (typeof e == "function")
    return we(s, { then: e });
  const t = e.pending != null ? f(e.pending()) : E, r = e.then, n = e.error != null ? (i) => f(e.error(i)) : () => E;
  return (i) => {
    let l = !0;
    const c = s(), a = i.makeRef();
    let h = f(t)(a);
    return c.then(
      (u) => {
        l && (h(!0), h = f(r(u))(a));
      },
      (u) => {
        l && (h(!0), h = f(n(u))(a));
      }
    ), (u) => {
      l = !1, h(u), a.clear(u);
    };
  };
}, Zt = (s, e) => we(() => s, e), Ce = (s, e, t) => (r) => r.on(s, e, t), yt = (s) => Ce("click", (e) => {
  e.preventDefault();
  const t = e.target;
  setTimeout(() => {
    const r = t.ownerDocument != null ? t == null ? void 0 : t.checked : void 0;
    r != null && s(!r);
  }, 0);
}), J = new Proxy(
  {},
  {
    /**
     * @param name - The name of the event handler.
     * @param fn - The function to call when the event is triggered.
     * @returns A `Renderable` function that adds the event listener to the element.
     */
    get: (s, e) => (t) => Ce(e, t)
  }
), Tt = (s) => (e) => {
  const t = e.target;
  s(t.value);
}, bt = (s) => (e) => {
  const t = e.target;
  s(t.valueAsNumber);
}, St = (s) => (e) => {
  const t = e.target;
  if (t.value === "")
    return;
  const r = t.value.split("-"), n = new Date(
    Number(r[0]),
    Number(r[1]) - 1,
    Number(r[2].substring(0, 2))
  );
  s(n);
}, At = (s) => (e) => {
  const t = e.target;
  if (t.value === "")
    return;
  const r = t.value.split("T"), n = r[0].split("-"), i = new Date(
    Number(n[0]),
    Number(n[1]) - 1,
    Number(n[2])
  ), l = r[1].split(":");
  i.setHours(Number(l[0])), i.setMinutes(Number(l[1])), i.setSeconds(Number(l[2])), s(i);
}, Kt = (s) => (e) => {
  const t = e.target;
  s(t.checked);
}, es = (s) => (e) => {
  e.preventDefault(), s();
}, ts = (s) => (e) => {
  e.stopPropagation(), s();
}, ss = (s) => (e) => {
  e.stopImmediatePropagation(), s();
}, rs = (s, e = "input") => S(M.valueAsDate(s), J[e](St(s.set))), ns = (s, e = "input") => S(M.valueAsDate(s), J[e](At(s.set))), is = (s, e = "input") => S(M.valueAsNumber(s), J[e](bt(s.set))), os = (s, e = "input") => S(M.value(s), J[e](Tt(s.set))), ls = (s) => S(M.checked(s), yt(s.set)), G = (s, e) => {
  if (d.is(s))
    return (r) => {
      const n = r.makeRef();
      let i, l;
      const c = s.map((u) => Object.keys(u)[0]);
      let a;
      const h = c.on((u) => {
        if (u !== a) {
          l == null || l.dispose(), i == null || i(!0), l = s.map((T) => T[u]);
          const m = e[u](l);
          i = f(m)(n), a = u;
        }
      });
      return (u) => {
        l == null || l.dispose(), h(), n.clear(u), i == null || i(u);
      };
    };
  const t = Object.keys(s)[0];
  return f(e[t](W(s[t])));
}, Pe = (s, e, t) => G(
  y.map(s, (r) => ({ [r[e]]: r })),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  t
), cs = (s, e) => Pe(s, "kind", e), as = (s, e) => {
  const t = y.map(s, ([r, n]) => ({ [r]: n }));
  return G(t, e);
}, us = (s, e) => Pe(s, "type", e), Et = (s, e) => G(
  y.map(s, (t) => ({ [t]: !0 })),
  e
), hs = (s, e = {}) => (t) => {
  const r = (e == null ? void 0 : e.firstSeparator) ?? s, n = (e == null ? void 0 : e.lastSeparator) ?? s;
  return Et(
    t.map((i) => i.isFirst ? "first" : i.isLast ? "last" : "other"),
    {
      first: r,
      last: n,
      other: s
    }
  );
}, Le = (s, e) => (t) => {
  const r = Object.values(s).reduce(
    (n, i) => {
      const l = t.getProvider(i);
      return Reflect.set(n, i, l), n;
    },
    {}
  );
  return f(e(r))(t);
}, fs = (s, e) => (t) => {
  const r = [], n = Object.entries(s).reduce(
    (i, [l, c]) => (r.push(
      c((a) => (Reflect.set(i, l, a), null))(t)
    ), i),
    {}
  );
  return r.push(f(e(n))(t)), (i) => {
    r.forEach((l) => l(i));
  };
}, _t = (s, e) => Le([s], (t) => f(e(t[s]))), ds = (s, e) => Le(s, (t) => f(e(t))), ps = (s) => (e) => {
  const t = s(e);
  return t == null ? () => {
  } : f(t)(e);
}, gs = (s) => (e) => (e.appendOrInsert(s), (t) => {
  t && R(s);
}), vt = (s, e, t) => {
  if (d.is(s)) {
    const r = s;
    return (n) => {
      const i = n.makeRef();
      let l = () => {
      }, c = !1, a = null;
      const h = r.on((u) => {
        u == null ? (l(!0), l = f((t == null ? void 0 : t()) ?? E)(i), c = !1, a == null || a.dispose(), a = null) : (a == null ? a = v(u) : a.value = u, c || (l(!0), l = f(e(a))(
          i
        ), c = !0));
      });
      return (u) => {
        a == null || a.dispose(), h(), l == null || l(u), i.clear(u);
      };
    };
  } else {
    const r = s;
    if (r == null) {
      const n = t == null ? void 0 : t();
      return n != null ? f(n) : E;
    }
    return f(e(W(r)));
  }
}, ms = (...s) => (e, t) => (r) => {
  const n = r.makeRef();
  if (s.some(
    (p) => !d.is(p) && p == null
  ))
    return (t != null ? f(t == null ? void 0 : t()) : E)(
      n
    );
  const l = s.map(() => null), c = s.map(
    (p) => d.is(p) ? p.value != null : p != null
  );
  let a = null;
  const h = v(c.every((p) => p)), u = (p, g) => {
    if (p.value != null) {
      if (l[g] == null) {
        const w = v(p.value);
        l[g] = w;
      } else
        l[g].value = p.value;
      c[g] = !0;
    } else
      c[g] = !1;
  };
  let m = s.length - 1;
  const T = s.map((p, g) => {
    if (!d.is(p)) {
      const w = v(p);
      return l[g] = w, () => {
      };
    }
    return p.on(() => {
      u(p, g), m === 0 ? h.value = c.every((w) => w) : m--;
    });
  });
  return h.on((p) => {
    a == null || a(!0), a = null, p ? a = f(e(...l))(n) : a = f((t == null ? void 0 : t()) ?? E)(n);
  }), (p) => {
    l.forEach((g) => g == null ? void 0 : g.dispose()), h.dispose(), T.forEach((g) => g()), a == null || a(p), n.clear(p);
  };
}, Z = (...s) => (e) => (t) => s.forEach((r) => r(t, e)), xe = (s, e, t) => vt(
  y.map(s, (r) => r ? !0 : null),
  e,
  t ?? void 0
), ys = (s, e, t) => xe(
  y.map(s, (r) => !r),
  e,
  t
), Oe = (s, e, t) => t != null ? Oe(s, (r) => {
  const n = new Y(
    r.index,
    r.total.map((i) => i - 1)
  );
  return S(
    Z(n.dispose),
    f(e(r)),
    xe(
      r.isLast,
      () => E,
      () => t(n)
    )
  );
}) : d.is(s) ? (r) => {
  const n = r.makeRef(), i = [], l = s.on((c) => {
    const a = i.splice(c);
    for (const h of a)
      h(!0);
    for (let h = i.length; h < c; h++) {
      const u = new Y(h, s);
      i.push(f(e(u))(n));
    }
  });
  return (c) => {
    l();
    for (const a of i)
      a(c);
    i.length = 0, n.clear(c);
  };
} : S(
  ...Array.from({ length: s }, (r, n) => n).map(
    (r) => f(e(new Y(r, W(s))))
  )
), Ts = (s, e, t) => {
  const r = y.map(s, (i) => i.length), n = y.toSignal(s);
  return Oe(
    r,
    (i) => {
      const l = n.map((c) => c[i.index]);
      return S(
        Z(l.dispose),
        f(e(l, i))
      );
    },
    t
  );
}, wt = (s) => (e) => {
  if (e.isBrowserDOM()) {
    const t = s(e);
    if (t != null)
      return f(t)(e);
  }
  return () => {
  };
}, bs = (s) => (e) => {
  if (e.isHeadlessDOM()) {
    const t = s(e);
    if (t)
      return f(t)(e);
  }
  return () => {
  };
}, Ss = (s) => wt((e) => s(e.element)), As = (s, e) => {
  if (d.is(s)) {
    const t = s;
    return (r) => {
      r = r.makeRef();
      const n = t.map((c) => f(e(c)));
      let i = () => {
      };
      const l = n.on((c) => {
        i(!0), i = c(r);
      });
      return (c) => {
        l(), i(c);
      };
    };
  }
  return f(e(s));
}, Es = (s, e, t = () => E) => G(
  y.map(
    s,
    (r) => r.length > 0 ? { notEmpty: r } : { whenEmpty: null }
  ),
  {
    notEmpty: (r) => e(r),
    whenEmpty: () => t()
  }
), _s = (s, e) => (t) => {
  const r = t.makePortal(s);
  return Q(f(e), r);
}, Ct = (s) => Symbol(s), De = (s, e) => (t) => f(e)(t.withProviders(s)), vs = (...s) => s.length > 0 ? s.reduceRight((e, t) => (r) => e(t(r))) : f, Pt = (s, e, t) => De({ [s]: e }, f(t)), ws = (s, e) => De(s, f(e)), Me = Ct("Probe"), P = /* @__PURE__ */ new Map(), Lt = ({
  identifier: s,
  callback: e = () => {
  },
  child: t,
  timeout: r = 10
}) => {
  if (P.has(s))
    throw new Error(`Probe already exists: ${s.description}`);
  const n = setTimeout(() => e("timeout"), r);
  P.set(s, {
    counter: 0,
    timeoutId: n
  });
  const i = (l) => {
    clearTimeout(n);
    const c = P.get(l);
    if (c == null)
      throw new Error(`Probe not found: ${l.description}`);
    --c.counter === 0 ? (e("resolved"), P.delete(l)) : P.set(l, c);
  };
  return S(
    Z(() => clearTimeout(n)),
    Pt(Me, i, t)
  );
}, xt = (s, e) => _t(Me, (t) => {
  const r = P.get(s);
  return r == null ? e(() => {
  }) : (clearTimeout(r.timeoutId), r.counter++, e(() => t(s)));
}), He = Symbol("globalProbe"), Cs = ({
  callback: s,
  timeout: e
}, t) => Lt({ identifier: He, callback: s, child: t, timeout: e }), Ps = (s) => xt(He, s), Ot = (s, e) => (t) => {
  const r = t.getStyle(s);
  return t.setStyle(s, e), (n) => {
    n && t.setStyle(s, r);
  };
}, Dt = (s, e) => (t) => {
  const r = t.getStyle(s);
  return e.on((n) => t.setStyle(s, n)), (n) => {
    n && t.setStyle(s, r);
  };
}, Ls = new Proxy(
  {},
  {
    /**
     * Creates a renderable component for the specified `style` property.
     *
     * @param _ - The target object.
     * @param name - The name of the CSS style property.
     * @returns The renderable component for the specified attribute.
     *
     */
    get: (s, e) => (t) => d.is(t) ? Dt(e, t) : Ot(e, t)
  }
);
export {
  Zt as Async,
  ls as BindChecked,
  rs as BindDate,
  ns as BindDateTime,
  is as BindNumber,
  os as BindText,
  x as BrowserContext,
  V as CLASS_PLACEHOLDER_ATTR,
  L as Computed,
  hs as Conjunction,
  gs as DOMNode,
  _e as El,
  ve as ElNS,
  Y as ElementPosition,
  E as Empty,
  vt as Ensure,
  ms as EnsureAll,
  Ts as ForEach,
  S as Fragment,
  qt as HeadlessAdapter,
  O as HeadlessContext,
  at as HeadlessElement,
  Se as HeadlessPortal,
  ut as HeadlessText,
  As as MapSignal,
  le as MemoryStore,
  Es as NotEmpty,
  wt as OnBrowserCtx,
  yt as OnChecked,
  ps as OnCtx,
  Z as OnDispose,
  Ss as OnElement,
  bs as OnHeadlessCtx,
  G as OneOf,
  Pe as OneOfField,
  cs as OneOfKind,
  as as OneOfTuple,
  us as OneOfType,
  Et as OneOfValue,
  _s as Portal,
  N as Prop,
  vs as Provide,
  Cs as ProvideGlobalProbe,
  Lt as ProvideProbe,
  ge as ProviderNotFoundError,
  Xe as RenderingError,
  Oe as Repeat,
  d as Signal,
  we as Task,
  Bt as TextNode,
  ys as Unless,
  fs as Use,
  Ps as UseGlobalProbe,
  xt as UseProbe,
  _t as UseProvider,
  ds as UseProviders,
  y as Value,
  xe as When,
  Pt as WithProvider,
  ws as WithProviders,
  me as _NODE_PLACEHOLDER_ATTR,
  Ge as _getSelfOrParentElement,
  pe as _isElement,
  Je as _makeGetter,
  We as _makeSetter,
  R as _removeDOMNode,
  Ee as _signalText,
  Ae as _staticText,
  Nt as animateSignal,
  Ue as animateSignals,
  Wt as aria,
  M as attr,
  Ut as dataAttr,
  Kt as emitChecked,
  es as emitPreventDefault,
  ss as emitStopImmediatePropagation,
  ts as emitStopPropagation,
  Tt as emitValue,
  St as emitValueAsDate,
  At as emitValueAsDateTime,
  bt as emitValueAsNumber,
  je as endInterpolate,
  oe as getWindow,
  qe as guessInterpolate,
  Xt as html,
  Yt as input,
  Ve as interpolateDate,
  Ne as interpolateNumber,
  Re as interpolateString,
  kt as localStorageProp,
  z as makeComputed,
  Ht as makeComputedOf,
  Rt as makeComputedRecord,
  Be as makeEffect,
  $t as makeEffectOf,
  v as makeProp,
  Ct as makeProviderMark,
  W as makeSignal,
  Qt as math,
  Gt as mathAttr,
  J as on,
  Me as probeMarker,
  Vt as render,
  Q as renderWithContext,
  f as renderableOfTNode,
  Ft as restoreTempoPlaceholders,
  jt as runHeadless,
  It as sessionStorageProp,
  ce as storedProp,
  Ls as style,
  zt as svg,
  Jt as svgAttr
};
