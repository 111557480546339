var fe = Object.defineProperty;
var de = (t, e, n) => e in t ? fe(t, e, { enumerable: !0, configurable: !0, writable: !0, value: n }) : t[e] = n;
var Ct = (t, e, n) => de(t, typeof e != "symbol" ? e + "" : e, n);
import { makeProviderMark as Bt, Fragment as _, OnBrowserCtx as xt, Async as Pt, OnHeadlessCtx as he, UseProvider as Ut, makeProp as W, renderableOfTNode as lt, getWindow as vt, Signal as _t, html as It, on as jt, Value as J, attr as Yt, WithProvider as me, OnDispose as k, Empty as nt, OneOfType as bt, makeSignal as pe, OnElement as G, Portal as qt, When as Kt, Ensure as ge, OneOfTuple as ye, makeComputedOf as we, Prop as xe } from "@tempots/dom";
import { delayed as Xt, AsyncResult as P, interval as ve } from "@tempots/std";
const be = (t) => {
  const e = t.split("/").pop();
  if (e == null || e.startsWith(".")) return;
  const n = e.split(".") || [];
  return n.length > 1 ? "." + n.pop() : void 0;
}, Ae = (t, e) => {
  const n = be(e);
  return n != null && (t.length === 0 || !t.some((o) => n == o));
}, Re = (t, e, n, o) => {
  let r = t.target;
  for (; r != null && !(r instanceof HTMLAnchorElement); )
    r = r.parentElement;
  if (r == null)
    return !0;
  const i = r;
  if (t.button !== 0 || t.ctrlKey || t.metaKey || i.target !== "_self" && i.target !== "" || i.getAttribute("download") != null)
    return !0;
  const { pathname: s, search: c, hash: a } = i;
  if (o) {
    const l = s + c + a, f = i.getAttribute("href");
    if (!(f != null && f.startsWith("#")) && f !== l)
      return !0;
  }
  return e ? !1 : Ae(n, s);
}, Ee = (t, e = {
  ignoreUrlWithExtension: !0,
  allowedExtensions: [],
  ignoreExternalUrl: !0
}) => {
  const n = e.ignoreUrlWithExtension === !0 && Array.isArray(e.allowedExtensions) ? e.allowedExtensions.map(
    (o) => o.startsWith(".") ? o : "." + o
  ) : [];
  return (o) => {
    Re(
      o,
      e.ignoreUrlWithExtension ?? !0,
      n,
      e.ignoreExternalUrl ?? !0
    ) || t() && o.preventDefault();
  };
}, Oe = Bt("LocationProvider"), Cn = (t) => _(
  xt(() => Pt(
    import("./browser-location-12vUH3Kv.js").then((e) => e.ProvideBrowserLocation),
    (e) => e(t)
  )),
  he((e) => Pt(
    import("./headless-location-Ba_ht5ZQ.js").then((n) => n.ProvideHeadlessLocation),
    (n) => n(e.container.currentURL, t)
  ))
), Jt = (t) => Ut(Oe, (e) => (n) => {
  const o = W(e.value, e.equals);
  e.feedProp(o), o.on(e.set);
  const r = lt(t(o))(n);
  return (i) => {
    o.dispose(), r(i);
  };
}), Pn = (t, e) => t.pathname === e.pathname && JSON.stringify(t.search) === JSON.stringify(e.search) && t.hash === e.hash, Le = (t, e) => {
  var i;
  const n = new URL(t, e ?? ((i = vt()) == null ? void 0 : i.location.toString())), o = Object.fromEntries(n.searchParams.entries());
  let r = n.hash;
  return r.startsWith("#") && (r = r.substring(1)), {
    pathname: n.pathname,
    search: o,
    hash: r === "" ? void 0 : r
  };
}, ke = (t, e) => {
  const n = Le(e);
  return t.set(n), t;
}, Dn = (t) => {
  const n = new URLSearchParams(t.search).toString(), o = t.hash;
  return `${t.pathname}${n ? `?${n}` : ""}${o ? `#${o}` : ""}`;
}, Se = (t, ...e) => {
  if (typeof t == "string" || _t.is(t))
    return Se({ href: t }, ...e);
  const { href: n, ...o } = t;
  return Jt((r) => It.a(
    jt.click(
      Ee(() => (ke(r, J.get(n)), !0), o)
    ),
    Yt.href(n),
    ...e
  ));
}, Gt = Bt("Appearance"), Wn = (t) => {
  const e = vt(), n = e != null && e.matchMedia != null && e.matchMedia("(prefers-color-scheme: dark)").matches, o = W(n ? "dark" : "light"), r = (s) => {
    o.set(s.matches ? "dark" : "light");
  }, i = e != null && e.matchMedia != null ? e.matchMedia("(prefers-color-scheme: dark)") : void 0;
  return i == null || i.addEventListener("change", r), _(
    me(Gt, o, t),
    k(() => i == null ? void 0 : i.removeEventListener("change", r))
  );
}, Fn = (t) => Ut(Gt, t), Te = (t, e) => {
  if (typeof e == "function")
    return Te(t, { success: e });
  const n = e.failure ?? ((s) => _(
    k(s.on(console.error)),
    s.map((c) => `Error: ${c}`)
  )), o = e.success, r = e.loading ?? (() => nt), i = e.notAsked ?? (() => nt);
  return bt(J.toSignal(t), {
    AsyncSuccess: (s) => o(s.$.value),
    AsyncFailure: (s) => n(s.$.error),
    Loading: (s) => r(s.$.previousValue ?? pe(void 0)),
    NotAsked: i
  });
}, Mn = (t = 10) => G((e) => k(Xt(() => e == null ? void 0 : e.focus(), t))), Nn = (t = 10) => G((e) => {
  const n = Xt(() => e.select(), t);
  return k(n);
}), $n = G((t) => {
  const e = t.style.getPropertyValue(":empty");
  return t.style.setProperty(":empty", "display:none"), k((n) => {
    n && t.style.setProperty(":empty", e);
  });
}), Vn = (t) => qt("head > title", Yt.innerText(t)), Ce = {
  partial: {
    root: null,
    rootMargin: "0px",
    threshold: 0
  },
  full: {
    root: null,
    rootMargin: "0px",
    threshold: 1
  }
}, et = {
  partial: /* @__PURE__ */ new Map(),
  full: /* @__PURE__ */ new Map()
}, q = {
  partial: null,
  full: null
};
function Pe(t) {
  return q[t] == null && (q[t] = new IntersectionObserver((e) => {
    e.forEach((n) => {
      const o = et[t].get(n.target);
      o == null || o.set(n.isIntersecting);
    });
  }, Ce[t])), q[t];
}
const De = (t, e) => {
  const n = W(!1);
  return _(
    G((o) => {
      const r = typeof IntersectionObserver < "u" ? Pe(t) : null;
      return et[t].set(o, n), r == null || r.observe(o), k(() => {
        var i;
        n.dispose(), r == null || r.unobserve(o), et[t].delete(o), et[t].size === 0 && ((i = q[t]) == null || i.disconnect(), q[t] = null);
      });
    }),
    lt(e(n))
  );
}, Hn = (t, e, n) => De(t, (o) => Kt(o, e, n ?? (() => nt))), ot = Math.min, M = Math.max, rt = Math.round, tt = Math.floor, O = (t) => ({
  x: t,
  y: t
}), We = {
  left: "right",
  right: "left",
  bottom: "top",
  top: "bottom"
}, Fe = {
  start: "end",
  end: "start"
};
function Dt(t, e, n) {
  return M(t, ot(e, n));
}
function at(t, e) {
  return typeof t == "function" ? t(e) : t;
}
function N(t) {
  return t.split("-")[0];
}
function ut(t) {
  return t.split("-")[1];
}
function Qt(t) {
  return t === "x" ? "y" : "x";
}
function Zt(t) {
  return t === "y" ? "height" : "width";
}
function B(t) {
  return ["top", "bottom"].includes(N(t)) ? "y" : "x";
}
function te(t) {
  return Qt(B(t));
}
function Me(t, e, n) {
  n === void 0 && (n = !1);
  const o = ut(t), r = te(t), i = Zt(r);
  let s = r === "x" ? o === (n ? "end" : "start") ? "right" : "left" : o === "start" ? "bottom" : "top";
  return e.reference[i] > e.floating[i] && (s = it(s)), [s, it(s)];
}
function Ne(t) {
  const e = it(t);
  return [gt(t), e, gt(e)];
}
function gt(t) {
  return t.replace(/start|end/g, (e) => Fe[e]);
}
function $e(t, e, n) {
  const o = ["left", "right"], r = ["right", "left"], i = ["top", "bottom"], s = ["bottom", "top"];
  switch (t) {
    case "top":
    case "bottom":
      return n ? e ? r : o : e ? o : r;
    case "left":
    case "right":
      return e ? i : s;
    default:
      return [];
  }
}
function Ve(t, e, n, o) {
  const r = ut(t);
  let i = $e(N(t), n === "start", o);
  return r && (i = i.map((s) => s + "-" + r), e && (i = i.concat(i.map(gt)))), i;
}
function it(t) {
  return t.replace(/left|right|bottom|top/g, (e) => We[e]);
}
function He(t) {
  return {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    ...t
  };
}
function ze(t) {
  return typeof t != "number" ? He(t) : {
    top: t,
    right: t,
    bottom: t,
    left: t
  };
}
function st(t) {
  const {
    x: e,
    y: n,
    width: o,
    height: r
  } = t;
  return {
    width: o,
    height: r,
    top: n,
    left: e,
    right: e + o,
    bottom: n + r,
    x: e,
    y: n
  };
}
function Wt(t, e, n) {
  let {
    reference: o,
    floating: r
  } = t;
  const i = B(e), s = te(e), c = Zt(s), a = N(e), l = i === "y", f = o.x + o.width / 2 - r.width / 2, u = o.y + o.height / 2 - r.height / 2, h = o[c] / 2 - r[c] / 2;
  let d;
  switch (a) {
    case "top":
      d = {
        x: f,
        y: o.y - r.height
      };
      break;
    case "bottom":
      d = {
        x: f,
        y: o.y + o.height
      };
      break;
    case "right":
      d = {
        x: o.x + o.width,
        y: u
      };
      break;
    case "left":
      d = {
        x: o.x - r.width,
        y: u
      };
      break;
    default:
      d = {
        x: o.x,
        y: o.y
      };
  }
  switch (ut(e)) {
    case "start":
      d[s] -= h * (n && l ? -1 : 1);
      break;
    case "end":
      d[s] += h * (n && l ? -1 : 1);
      break;
  }
  return d;
}
const Be = async (t, e, n) => {
  const {
    placement: o = "bottom",
    strategy: r = "absolute",
    middleware: i = [],
    platform: s
  } = n, c = i.filter(Boolean), a = await (s.isRTL == null ? void 0 : s.isRTL(e));
  let l = await s.getElementRects({
    reference: t,
    floating: e,
    strategy: r
  }), {
    x: f,
    y: u
  } = Wt(l, o, a), h = o, d = {}, m = 0;
  for (let g = 0; g < c.length; g++) {
    const {
      name: y,
      fn: p
    } = c[g], {
      x: w,
      y: x,
      data: b,
      reset: v
    } = await p({
      x: f,
      y: u,
      initialPlacement: o,
      placement: h,
      strategy: r,
      middlewareData: d,
      rects: l,
      platform: s,
      elements: {
        reference: t,
        floating: e
      }
    });
    f = w ?? f, u = x ?? u, d = {
      ...d,
      [y]: {
        ...d[y],
        ...b
      }
    }, v && m <= 50 && (m++, typeof v == "object" && (v.placement && (h = v.placement), v.rects && (l = v.rects === !0 ? await s.getElementRects({
      reference: t,
      floating: e,
      strategy: r
    }) : v.rects), {
      x: f,
      y: u
    } = Wt(l, h, a)), g = -1);
  }
  return {
    x: f,
    y: u,
    placement: h,
    strategy: r,
    middlewareData: d
  };
};
async function ee(t, e) {
  var n;
  e === void 0 && (e = {});
  const {
    x: o,
    y: r,
    platform: i,
    rects: s,
    elements: c,
    strategy: a
  } = t, {
    boundary: l = "clippingAncestors",
    rootBoundary: f = "viewport",
    elementContext: u = "floating",
    altBoundary: h = !1,
    padding: d = 0
  } = at(e, t), m = ze(d), y = c[h ? u === "floating" ? "reference" : "floating" : u], p = st(await i.getClippingRect({
    element: (n = await (i.isElement == null ? void 0 : i.isElement(y))) == null || n ? y : y.contextElement || await (i.getDocumentElement == null ? void 0 : i.getDocumentElement(c.floating)),
    boundary: l,
    rootBoundary: f,
    strategy: a
  })), w = u === "floating" ? {
    x: o,
    y: r,
    width: s.floating.width,
    height: s.floating.height
  } : s.reference, x = await (i.getOffsetParent == null ? void 0 : i.getOffsetParent(c.floating)), b = await (i.isElement == null ? void 0 : i.isElement(x)) ? await (i.getScale == null ? void 0 : i.getScale(x)) || {
    x: 1,
    y: 1
  } : {
    x: 1,
    y: 1
  }, v = st(i.convertOffsetParentRelativeRectToViewportRelativeRect ? await i.convertOffsetParentRelativeRectToViewportRelativeRect({
    elements: c,
    rect: w,
    offsetParent: x,
    strategy: a
  }) : w);
  return {
    top: (p.top - v.top + m.top) / b.y,
    bottom: (v.bottom - p.bottom + m.bottom) / b.y,
    left: (p.left - v.left + m.left) / b.x,
    right: (v.right - p.right + m.right) / b.x
  };
}
const Ue = function(t) {
  return t === void 0 && (t = {}), {
    name: "flip",
    options: t,
    async fn(e) {
      var n, o;
      const {
        placement: r,
        middlewareData: i,
        rects: s,
        initialPlacement: c,
        platform: a,
        elements: l
      } = e, {
        mainAxis: f = !0,
        crossAxis: u = !0,
        fallbackPlacements: h,
        fallbackStrategy: d = "bestFit",
        fallbackAxisSideDirection: m = "none",
        flipAlignment: g = !0,
        ...y
      } = at(t, e);
      if ((n = i.arrow) != null && n.alignmentOffset)
        return {};
      const p = N(r), w = B(c), x = N(c) === c, b = await (a.isRTL == null ? void 0 : a.isRTL(l.floating)), v = h || (x || !g ? [it(c)] : Ne(c)), V = m !== "none";
      !h && V && v.push(...Ve(c, g, m, b));
      const ae = [c, ...v], mt = await ee(e, y), Z = [];
      let j = ((o = i.flip) == null ? void 0 : o.overflows) || [];
      if (f && Z.push(mt[p]), u) {
        const F = Me(r, s, b);
        Z.push(mt[F[0]], mt[F[1]]);
      }
      if (j = [...j, {
        placement: r,
        overflows: Z
      }], !Z.every((F) => F <= 0)) {
        var Lt, kt;
        const F = (((Lt = i.flip) == null ? void 0 : Lt.index) || 0) + 1, Tt = ae[F];
        if (Tt)
          return {
            data: {
              index: F,
              overflows: j
            },
            reset: {
              placement: Tt
            }
          };
        let Y = (kt = j.filter((H) => H.overflows[0] <= 0).sort((H, T) => H.overflows[1] - T.overflows[1])[0]) == null ? void 0 : kt.placement;
        if (!Y)
          switch (d) {
            case "bestFit": {
              var St;
              const H = (St = j.filter((T) => {
                if (V) {
                  const C = B(T.placement);
                  return C === w || // Create a bias to the `y` side axis due to horizontal
                  // reading directions favoring greater width.
                  C === "y";
                }
                return !0;
              }).map((T) => [T.placement, T.overflows.filter((C) => C > 0).reduce((C, ue) => C + ue, 0)]).sort((T, C) => T[1] - C[1])[0]) == null ? void 0 : St[0];
              H && (Y = H);
              break;
            }
            case "initialPlacement":
              Y = c;
              break;
          }
        if (r !== Y)
          return {
            reset: {
              placement: Y
            }
          };
      }
      return {};
    }
  };
};
async function _e(t, e) {
  const {
    placement: n,
    platform: o,
    elements: r
  } = t, i = await (o.isRTL == null ? void 0 : o.isRTL(r.floating)), s = N(n), c = ut(n), a = B(n) === "y", l = ["left", "top"].includes(s) ? -1 : 1, f = i && a ? -1 : 1, u = at(e, t);
  let {
    mainAxis: h,
    crossAxis: d,
    alignmentAxis: m
  } = typeof u == "number" ? {
    mainAxis: u,
    crossAxis: 0,
    alignmentAxis: null
  } : {
    mainAxis: u.mainAxis || 0,
    crossAxis: u.crossAxis || 0,
    alignmentAxis: u.alignmentAxis
  };
  return c && typeof m == "number" && (d = c === "end" ? m * -1 : m), a ? {
    x: d * f,
    y: h * l
  } : {
    x: h * l,
    y: d * f
  };
}
const Ie = function(t) {
  return t === void 0 && (t = 0), {
    name: "offset",
    options: t,
    async fn(e) {
      var n, o;
      const {
        x: r,
        y: i,
        placement: s,
        middlewareData: c
      } = e, a = await _e(e, t);
      return s === ((n = c.offset) == null ? void 0 : n.placement) && (o = c.arrow) != null && o.alignmentOffset ? {} : {
        x: r + a.x,
        y: i + a.y,
        data: {
          ...a,
          placement: s
        }
      };
    }
  };
}, je = function(t) {
  return t === void 0 && (t = {}), {
    name: "shift",
    options: t,
    async fn(e) {
      const {
        x: n,
        y: o,
        placement: r
      } = e, {
        mainAxis: i = !0,
        crossAxis: s = !1,
        limiter: c = {
          fn: (y) => {
            let {
              x: p,
              y: w
            } = y;
            return {
              x: p,
              y: w
            };
          }
        },
        ...a
      } = at(t, e), l = {
        x: n,
        y: o
      }, f = await ee(e, a), u = B(N(r)), h = Qt(u);
      let d = l[h], m = l[u];
      if (i) {
        const y = h === "y" ? "top" : "left", p = h === "y" ? "bottom" : "right", w = d + f[y], x = d - f[p];
        d = Dt(w, d, x);
      }
      if (s) {
        const y = u === "y" ? "top" : "left", p = u === "y" ? "bottom" : "right", w = m + f[y], x = m - f[p];
        m = Dt(w, m, x);
      }
      const g = c.fn({
        ...e,
        [h]: d,
        [u]: m
      });
      return {
        ...g,
        data: {
          x: g.x - n,
          y: g.y - o,
          enabled: {
            [h]: i,
            [u]: s
          }
        }
      };
    }
  };
};
function ft() {
  return typeof window < "u";
}
function I(t) {
  return ne(t) ? (t.nodeName || "").toLowerCase() : "#document";
}
function A(t) {
  var e;
  return (t == null || (e = t.ownerDocument) == null ? void 0 : e.defaultView) || window;
}
function S(t) {
  var e;
  return (e = (ne(t) ? t.ownerDocument : t.document) || window.document) == null ? void 0 : e.documentElement;
}
function ne(t) {
  return ft() ? t instanceof Node || t instanceof A(t).Node : !1;
}
function R(t) {
  return ft() ? t instanceof Element || t instanceof A(t).Element : !1;
}
function L(t) {
  return ft() ? t instanceof HTMLElement || t instanceof A(t).HTMLElement : !1;
}
function Ft(t) {
  return !ft() || typeof ShadowRoot > "u" ? !1 : t instanceof ShadowRoot || t instanceof A(t).ShadowRoot;
}
function Q(t) {
  const {
    overflow: e,
    overflowX: n,
    overflowY: o,
    display: r
  } = E(t);
  return /auto|scroll|overlay|hidden|clip/.test(e + o + n) && !["inline", "contents"].includes(r);
}
function Ye(t) {
  return ["table", "td", "th"].includes(I(t));
}
function dt(t) {
  return [":popover-open", ":modal"].some((e) => {
    try {
      return t.matches(e);
    } catch {
      return !1;
    }
  });
}
function At(t) {
  const e = Rt(), n = R(t) ? E(t) : t;
  return n.transform !== "none" || n.perspective !== "none" || (n.containerType ? n.containerType !== "normal" : !1) || !e && (n.backdropFilter ? n.backdropFilter !== "none" : !1) || !e && (n.filter ? n.filter !== "none" : !1) || ["transform", "perspective", "filter"].some((o) => (n.willChange || "").includes(o)) || ["paint", "layout", "strict", "content"].some((o) => (n.contain || "").includes(o));
}
function qe(t) {
  let e = D(t);
  for (; L(e) && !U(e); ) {
    if (At(e))
      return e;
    if (dt(e))
      return null;
    e = D(e);
  }
  return null;
}
function Rt() {
  return typeof CSS > "u" || !CSS.supports ? !1 : CSS.supports("-webkit-backdrop-filter", "none");
}
function U(t) {
  return ["html", "body", "#document"].includes(I(t));
}
function E(t) {
  return A(t).getComputedStyle(t);
}
function ht(t) {
  return R(t) ? {
    scrollLeft: t.scrollLeft,
    scrollTop: t.scrollTop
  } : {
    scrollLeft: t.scrollX,
    scrollTop: t.scrollY
  };
}
function D(t) {
  if (I(t) === "html")
    return t;
  const e = (
    // Step into the shadow DOM of the parent of a slotted node.
    t.assignedSlot || // DOM Element detected.
    t.parentNode || // ShadowRoot detected.
    Ft(t) && t.host || // Fallback.
    S(t)
  );
  return Ft(e) ? e.host : e;
}
function oe(t) {
  const e = D(t);
  return U(e) ? t.ownerDocument ? t.ownerDocument.body : t.body : L(e) && Q(e) ? e : oe(e);
}
function K(t, e, n) {
  var o;
  e === void 0 && (e = []), n === void 0 && (n = !0);
  const r = oe(t), i = r === ((o = t.ownerDocument) == null ? void 0 : o.body), s = A(r);
  if (i) {
    const c = yt(s);
    return e.concat(s, s.visualViewport || [], Q(r) ? r : [], c && n ? K(c) : []);
  }
  return e.concat(r, K(r, [], n));
}
function yt(t) {
  return t.parent && Object.getPrototypeOf(t.parent) ? t.frameElement : null;
}
function re(t) {
  const e = E(t);
  let n = parseFloat(e.width) || 0, o = parseFloat(e.height) || 0;
  const r = L(t), i = r ? t.offsetWidth : n, s = r ? t.offsetHeight : o, c = rt(n) !== i || rt(o) !== s;
  return c && (n = i, o = s), {
    width: n,
    height: o,
    $: c
  };
}
function Et(t) {
  return R(t) ? t : t.contextElement;
}
function z(t) {
  const e = Et(t);
  if (!L(e))
    return O(1);
  const n = e.getBoundingClientRect(), {
    width: o,
    height: r,
    $: i
  } = re(e);
  let s = (i ? rt(n.width) : n.width) / o, c = (i ? rt(n.height) : n.height) / r;
  return (!s || !Number.isFinite(s)) && (s = 1), (!c || !Number.isFinite(c)) && (c = 1), {
    x: s,
    y: c
  };
}
const Ke = /* @__PURE__ */ O(0);
function ie(t) {
  const e = A(t);
  return !Rt() || !e.visualViewport ? Ke : {
    x: e.visualViewport.offsetLeft,
    y: e.visualViewport.offsetTop
  };
}
function Xe(t, e, n) {
  return e === void 0 && (e = !1), !n || e && n !== A(t) ? !1 : e;
}
function $(t, e, n, o) {
  e === void 0 && (e = !1), n === void 0 && (n = !1);
  const r = t.getBoundingClientRect(), i = Et(t);
  let s = O(1);
  e && (o ? R(o) && (s = z(o)) : s = z(t));
  const c = Xe(i, n, o) ? ie(i) : O(0);
  let a = (r.left + c.x) / s.x, l = (r.top + c.y) / s.y, f = r.width / s.x, u = r.height / s.y;
  if (i) {
    const h = A(i), d = o && R(o) ? A(o) : o;
    let m = h, g = yt(m);
    for (; g && o && d !== m; ) {
      const y = z(g), p = g.getBoundingClientRect(), w = E(g), x = p.left + (g.clientLeft + parseFloat(w.paddingLeft)) * y.x, b = p.top + (g.clientTop + parseFloat(w.paddingTop)) * y.y;
      a *= y.x, l *= y.y, f *= y.x, u *= y.y, a += x, l += b, m = A(g), g = yt(m);
    }
  }
  return st({
    width: f,
    height: u,
    x: a,
    y: l
  });
}
function Ot(t, e) {
  const n = ht(t).scrollLeft;
  return e ? e.left + n : $(S(t)).left + n;
}
function se(t, e, n) {
  n === void 0 && (n = !1);
  const o = t.getBoundingClientRect(), r = o.left + e.scrollLeft - (n ? 0 : (
    // RTL <body> scrollbar.
    Ot(t, o)
  )), i = o.top + e.scrollTop;
  return {
    x: r,
    y: i
  };
}
function Je(t) {
  let {
    elements: e,
    rect: n,
    offsetParent: o,
    strategy: r
  } = t;
  const i = r === "fixed", s = S(o), c = e ? dt(e.floating) : !1;
  if (o === s || c && i)
    return n;
  let a = {
    scrollLeft: 0,
    scrollTop: 0
  }, l = O(1);
  const f = O(0), u = L(o);
  if ((u || !u && !i) && ((I(o) !== "body" || Q(s)) && (a = ht(o)), L(o))) {
    const d = $(o);
    l = z(o), f.x = d.x + o.clientLeft, f.y = d.y + o.clientTop;
  }
  const h = s && !u && !i ? se(s, a, !0) : O(0);
  return {
    width: n.width * l.x,
    height: n.height * l.y,
    x: n.x * l.x - a.scrollLeft * l.x + f.x + h.x,
    y: n.y * l.y - a.scrollTop * l.y + f.y + h.y
  };
}
function Ge(t) {
  return Array.from(t.getClientRects());
}
function Qe(t) {
  const e = S(t), n = ht(t), o = t.ownerDocument.body, r = M(e.scrollWidth, e.clientWidth, o.scrollWidth, o.clientWidth), i = M(e.scrollHeight, e.clientHeight, o.scrollHeight, o.clientHeight);
  let s = -n.scrollLeft + Ot(t);
  const c = -n.scrollTop;
  return E(o).direction === "rtl" && (s += M(e.clientWidth, o.clientWidth) - r), {
    width: r,
    height: i,
    x: s,
    y: c
  };
}
function Ze(t, e) {
  const n = A(t), o = S(t), r = n.visualViewport;
  let i = o.clientWidth, s = o.clientHeight, c = 0, a = 0;
  if (r) {
    i = r.width, s = r.height;
    const l = Rt();
    (!l || l && e === "fixed") && (c = r.offsetLeft, a = r.offsetTop);
  }
  return {
    width: i,
    height: s,
    x: c,
    y: a
  };
}
function tn(t, e) {
  const n = $(t, !0, e === "fixed"), o = n.top + t.clientTop, r = n.left + t.clientLeft, i = L(t) ? z(t) : O(1), s = t.clientWidth * i.x, c = t.clientHeight * i.y, a = r * i.x, l = o * i.y;
  return {
    width: s,
    height: c,
    x: a,
    y: l
  };
}
function Mt(t, e, n) {
  let o;
  if (e === "viewport")
    o = Ze(t, n);
  else if (e === "document")
    o = Qe(S(t));
  else if (R(e))
    o = tn(e, n);
  else {
    const r = ie(t);
    o = {
      x: e.x - r.x,
      y: e.y - r.y,
      width: e.width,
      height: e.height
    };
  }
  return st(o);
}
function ce(t, e) {
  const n = D(t);
  return n === e || !R(n) || U(n) ? !1 : E(n).position === "fixed" || ce(n, e);
}
function en(t, e) {
  const n = e.get(t);
  if (n)
    return n;
  let o = K(t, [], !1).filter((c) => R(c) && I(c) !== "body"), r = null;
  const i = E(t).position === "fixed";
  let s = i ? D(t) : t;
  for (; R(s) && !U(s); ) {
    const c = E(s), a = At(s);
    !a && c.position === "fixed" && (r = null), (i ? !a && !r : !a && c.position === "static" && !!r && ["absolute", "fixed"].includes(r.position) || Q(s) && !a && ce(t, s)) ? o = o.filter((f) => f !== s) : r = c, s = D(s);
  }
  return e.set(t, o), o;
}
function nn(t) {
  let {
    element: e,
    boundary: n,
    rootBoundary: o,
    strategy: r
  } = t;
  const s = [...n === "clippingAncestors" ? dt(e) ? [] : en(e, this._c) : [].concat(n), o], c = s[0], a = s.reduce((l, f) => {
    const u = Mt(e, f, r);
    return l.top = M(u.top, l.top), l.right = ot(u.right, l.right), l.bottom = ot(u.bottom, l.bottom), l.left = M(u.left, l.left), l;
  }, Mt(e, c, r));
  return {
    width: a.right - a.left,
    height: a.bottom - a.top,
    x: a.left,
    y: a.top
  };
}
function on(t) {
  const {
    width: e,
    height: n
  } = re(t);
  return {
    width: e,
    height: n
  };
}
function rn(t, e, n) {
  const o = L(e), r = S(e), i = n === "fixed", s = $(t, !0, i, e);
  let c = {
    scrollLeft: 0,
    scrollTop: 0
  };
  const a = O(0);
  if (o || !o && !i)
    if ((I(e) !== "body" || Q(r)) && (c = ht(e)), o) {
      const h = $(e, !0, i, e);
      a.x = h.x + e.clientLeft, a.y = h.y + e.clientTop;
    } else r && (a.x = Ot(r));
  const l = r && !o && !i ? se(r, c) : O(0), f = s.left + c.scrollLeft - a.x - l.x, u = s.top + c.scrollTop - a.y - l.y;
  return {
    x: f,
    y: u,
    width: s.width,
    height: s.height
  };
}
function pt(t) {
  return E(t).position === "static";
}
function Nt(t, e) {
  if (!L(t) || E(t).position === "fixed")
    return null;
  if (e)
    return e(t);
  let n = t.offsetParent;
  return S(t) === n && (n = n.ownerDocument.body), n;
}
function le(t, e) {
  const n = A(t);
  if (dt(t))
    return n;
  if (!L(t)) {
    let r = D(t);
    for (; r && !U(r); ) {
      if (R(r) && !pt(r))
        return r;
      r = D(r);
    }
    return n;
  }
  let o = Nt(t, e);
  for (; o && Ye(o) && pt(o); )
    o = Nt(o, e);
  return o && U(o) && pt(o) && !At(o) ? n : o || qe(t) || n;
}
const sn = async function(t) {
  const e = this.getOffsetParent || le, n = this.getDimensions, o = await n(t.floating);
  return {
    reference: rn(t.reference, await e(t.floating), t.strategy),
    floating: {
      x: 0,
      y: 0,
      width: o.width,
      height: o.height
    }
  };
};
function cn(t) {
  return E(t).direction === "rtl";
}
const ln = {
  convertOffsetParentRelativeRectToViewportRelativeRect: Je,
  getDocumentElement: S,
  getClippingRect: nn,
  getOffsetParent: le,
  getElementRects: sn,
  getClientRects: Ge,
  getDimensions: on,
  getScale: z,
  isElement: R,
  isRTL: cn
};
function an(t, e) {
  let n = null, o;
  const r = S(t);
  function i() {
    var c;
    clearTimeout(o), (c = n) == null || c.disconnect(), n = null;
  }
  function s(c, a) {
    c === void 0 && (c = !1), a === void 0 && (a = 1), i();
    const {
      left: l,
      top: f,
      width: u,
      height: h
    } = t.getBoundingClientRect();
    if (c || e(), !u || !h)
      return;
    const d = tt(f), m = tt(r.clientWidth - (l + u)), g = tt(r.clientHeight - (f + h)), y = tt(l), w = {
      rootMargin: -d + "px " + -m + "px " + -g + "px " + -y + "px",
      threshold: M(0, ot(1, a)) || 1
    };
    let x = !0;
    function b(v) {
      const V = v[0].intersectionRatio;
      if (V !== a) {
        if (!x)
          return s();
        V ? s(!1, V) : o = setTimeout(() => {
          s(!1, 1e-7);
        }, 1e3);
      }
      x = !1;
    }
    try {
      n = new IntersectionObserver(b, {
        ...w,
        // Handle <iframe>s
        root: r.ownerDocument
      });
    } catch {
      n = new IntersectionObserver(b, w);
    }
    n.observe(t);
  }
  return s(!0), i;
}
function un(t, e, n, o) {
  o === void 0 && (o = {});
  const {
    ancestorScroll: r = !0,
    ancestorResize: i = !0,
    elementResize: s = typeof ResizeObserver == "function",
    layoutShift: c = typeof IntersectionObserver == "function",
    animationFrame: a = !1
  } = o, l = Et(t), f = r || i ? [...l ? K(l) : [], ...K(e)] : [];
  f.forEach((p) => {
    r && p.addEventListener("scroll", n, {
      passive: !0
    }), i && p.addEventListener("resize", n);
  });
  const u = l && c ? an(l, n) : null;
  let h = -1, d = null;
  s && (d = new ResizeObserver((p) => {
    let [w] = p;
    w && w.target === l && d && (d.unobserve(e), cancelAnimationFrame(h), h = requestAnimationFrame(() => {
      var x;
      (x = d) == null || x.observe(e);
    })), n();
  }), l && !a && d.observe(l), d.observe(e));
  let m, g = a ? $(t) : null;
  a && y();
  function y() {
    const p = $(t);
    g && (p.x !== g.x || p.y !== g.y || p.width !== g.width || p.height !== g.height) && n(), g = p, m = requestAnimationFrame(y);
  }
  return n(), () => {
    var p;
    f.forEach((w) => {
      r && w.removeEventListener("scroll", n), i && w.removeEventListener("resize", n);
    }), u == null || u(), (p = d) == null || p.disconnect(), d = null, a && cancelAnimationFrame(m);
  };
}
const fn = Ie, dn = je, $t = Ue, hn = (t, e, n) => {
  const o = /* @__PURE__ */ new Map(), r = {
    platform: ln,
    ...n
  }, i = {
    ...r.platform,
    _c: o
  };
  return Be(t, e, {
    ...r,
    platform: i
  });
}, zn = ({
  content: t,
  open: e,
  placement: n,
  offset: { mainAxis: o, crossAxis: r } = { mainAxis: 0, crossAxis: 0 }
}) => xt((i) => {
  const s = J.toSignal(e), c = i.element;
  return Kt(
    s,
    () => qt(
      "body",
      It.div(
        G((a) => {
          const l = a;
          return l.style.position = "absolute", k(
            un(c, l, async () => {
              const { x: f, y: u } = await hn(c, l, {
                placement: n,
                strategy: "absolute",
                middleware: [
                  $t(),
                  fn({ mainAxis: o, crossAxis: r }),
                  dn(),
                  $t()
                ]
              });
              l.style.top = `${u}px`, l.style.left = `${f}px`;
            })
          );
        }),
        t()
      )
    )
  );
}), mn = ({
  request: t,
  load: e,
  convertError: n
}) => {
  const o = W(P.notAsked), r = o.map(
    (u) => P.isSuccess(u) ? u.value : void 0
  ), i = o.map(
    (u) => P.isFailure(u) ? u.error : void 0
  ), s = o.map((u) => P.isLoading(u));
  let c;
  const a = async (u) => {
    c == null || c.abort(), c = new AbortController();
    const h = c.signal, d = o.get();
    o.set(P.loading(P.getOrUndefined(d)));
    try {
      const m = await e({ request: u, abortSignal: h, previous: d });
      c = void 0, o.set(P.success(m));
    } catch (m) {
      c = void 0, o.set(P.failure(n(m)));
    }
  }, l = () => a(t.get()), f = () => {
    c == null || c.abort(), c = void 0, o.dispose();
  };
  return o.onDispose(t.on(a)), {
    status: o,
    value: r,
    error: i,
    loading: s,
    reload: l,
    dispose: f
  };
}, pn = (t, e) => {
  const { status: n, dispose: o, reload: r } = t, { notAsked: i, loading: s, error: c, success: a } = e;
  return _(
    k(o),
    bt(n, {
      NotAsked: () => i != null ? i(r) : void 0,
      Loading: (l) => s != null ? s(l.$.previousValue, r) : ge(
        l.$.previousValue,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (f) => a(f, r),
        // TODO unsure why this is needed
        () => nt
      ),
      AsyncFailure: (l) => c != null ? c(l.$.error, r) : void 0,
      AsyncSuccess: (l) => a(l.$.value, r)
    })
  );
}, Bn = (t) => {
  const e = mn(t);
  return pn(e, t);
}, gn = (t, e) => {
  if (typeof e == "function")
    return gn(t, { success: e });
  const n = e.failure ?? ((r) => _(
    k(r.on(console.error)),
    r.map((i) => `Error: ${i}`)
  )), o = e.success;
  return bt(J.toSignal(t), {
    Success: (r) => o(r.$.value),
    Failure: (r) => n(r.$.error)
  });
}, Un = () => jt.focus((t) => {
  var e;
  return (e = t.target) == null ? void 0 : e.select();
}), _n = (t) => xt((e) => {
  const { element: n } = e, o = W({
    width: n.clientWidth,
    height: n.clientHeight
  }), r = lt(t(o))(e), i = () => {
    o.set({ width: n.clientWidth, height: n.clientHeight });
  };
  let s;
  return typeof ResizeObserver == "function" && (s = new ResizeObserver(i), s.observe(n)), k((c) => {
    s == null || s.disconnect(), r(c);
  });
}), In = (t) => (e) => {
  const n = vt(), o = W({
    width: (n == null ? void 0 : n.innerWidth) ?? 0,
    height: (n == null ? void 0 : n.innerHeight) ?? 0
  }), r = lt(t(o))(e), i = () => {
    o.set({
      width: (n == null ? void 0 : n.innerWidth) ?? 0,
      height: (n == null ? void 0 : n.innerHeight) ?? 0
    });
  };
  return n == null || n.addEventListener("resize", i), (s) => {
    n == null || n.removeEventListener("resize", i), r(s);
  };
}, yn = (t, e) => {
  const n = e.split("/").filter((r) => r !== ""), o = {};
  for (let r = 0; r < t.length; r++) {
    const i = t[r], s = n[r];
    if (!s && i.type !== "catch-all")
      return null;
    if (i.type === "literal") {
      if (i.value !== s)
        return null;
    } else if (i.type === "param")
      o[i.name] = s;
    else if (i.type === "catch-all")
      return { params: o, path: e };
  }
  return n.length !== t.length ? null : { params: o, path: e };
}, wn = (t) => t.split("/").map((e) => e.startsWith(":") ? { type: "param", name: e.slice(1) } : e === "*" ? { type: "catch-all" } : { type: "literal", value: e }).filter((e) => e.type !== "literal" || e.value !== ""), xn = (t) => {
  const e = t.map((n) => {
    const o = wn(n);
    return { route: n, segments: o };
  });
  return function(o) {
    for (const { segments: r, route: i } of e) {
      const s = yn(r, o);
      if (s)
        return { ...s, route: i };
    }
    return null;
  };
}, jn = (t) => {
  const e = xn(Object.keys(t));
  return Jt((n) => {
    const o = n.map((r) => {
      const i = e(r.pathname);
      if (i == null)
        throw console.error("No route found for", r), new Error("No route found");
      return {
        params: i.params,
        route: i.route,
        path: i.path,
        search: r.search,
        hash: r.hash
      };
    });
    return ye(
      o.map((r) => [r.route, r]),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      t
    );
  });
}, ct = 60 * 1e3, wt = 60 * ct, X = 24 * wt, Vt = 7 * X, Ht = 30 * X, vn = 365 * X, bn = [
  {
    max: ct * 90,
    value: ct,
    name: "minute",
    past: { singular: "a minute ago", plural: "{} minutes ago" },
    future: { singular: "in a minute", plural: "in {} minutes" }
  },
  {
    max: wt * 36,
    value: wt,
    name: "hour",
    past: { singular: "an hour ago", plural: "{} hours ago" },
    future: { singular: "in an hour", plural: "in {} hours" }
  },
  {
    max: X * 10,
    value: X,
    name: "day",
    past: { singular: "yesterday", plural: "{} days ago" },
    future: { singular: "tomorrow", plural: "in {} days" }
  },
  {
    max: Vt * 6,
    value: Vt,
    name: "week",
    past: { singular: "last week", plural: "{} weeks ago" },
    future: { singular: "in a week", plural: "in {} weeks" }
  },
  {
    max: Ht * 18,
    value: Ht,
    name: "month",
    past: { singular: "last month", plural: "{} months ago" },
    future: { singular: "in a month", plural: "in {} months" }
  },
  {
    max: 1 / 0,
    value: vn,
    name: "year",
    past: { singular: "last year", plural: "{} years ago" },
    future: { singular: "in a year", plural: "in {} years" }
  }
];
function zt(t, e, n, o) {
  const r = Math.round(t / e);
  return r <= 1 ? n : o.replace(
    "{}",
    r.toLocaleString(void 0, {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0
    })
  );
}
const An = (t = 1e3) => {
  const e = W(/* @__PURE__ */ new Date()), n = ve(() => e.set(/* @__PURE__ */ new Date()), t);
  return e.onDispose(n), e;
}, Rn = (t) => {
  const e = Math.abs(t);
  if (e < ct)
    return t < 0 ? "just now" : "in a moment";
  for (const n of bn)
    if (e < n.max)
      return t < 0 ? zt(e, n.value, n.past.singular, n.past.plural) : zt(e, n.value, n.future.singular, n.future.plural);
  throw new Error("unreachable");
}, En = (t, { now: e, frequency: n = 1e4 } = {}) => {
  const o = e != null ? _t.is(e) ? e.derive() : W(e) : An(n), r = we(
    t,
    o
  )((i, s) => i.getTime() - s.getTime());
  return r.onDispose(() => J.dispose(o)), r;
}, On = (t, e = {}) => {
  const n = En(t, e), o = n.map(Rn);
  return o.onDispose(n.dispose), o;
}, Yn = (t, e = {}) => On(t, e);
class Ln extends xe {
  constructor() {
    super(...arguments);
    /**
     * Triggers an update of the Ticker by incrementing its internal value.
     * @returns void
     */
    Ct(this, "tick", () => this.update((n) => n + 1));
  }
}
const qn = (t = 0) => new Ln(t, (e, n) => e === n);
export {
  Se as Anchor,
  Te as AsyncResultView,
  Mn as AutoFocus,
  Nn as AutoSelect,
  _n as ElementSize,
  Vn as HTMLTitle,
  $n as HiddenWhenEmpty,
  De as InViewport,
  Oe as LocationProviderMarker,
  zn as PopOver,
  Wn as ProvideAppearance,
  Cn as ProvideLocation,
  Bn as Resource,
  pn as ResourceDisplay,
  gn as ResultView,
  jn as Router,
  Un as SelectOnFocus,
  Ln as Ticker,
  Fn as UseAppearance,
  Jt as UseLocation,
  Hn as WhenInViewport,
  In as WindowSize,
  Ae as _checkExtensionCondition,
  be as _getExtension,
  xn as _makeRouteMatcher,
  wn as _parseRouteSegments,
  Gt as appearanceMarker,
  Pn as areLocationsEqual,
  Ee as handleAnchorClick,
  Le as locationFromURL,
  An as makeNowSignal,
  Yn as makeRelativeTime,
  En as makeRelativeTimeMillisSignal,
  On as makeRelativeTimeSignal,
  mn as makeResource,
  qn as makeTicker,
  yn as matchesRoute,
  ke as setLocationFromUrl,
  Rn as timeDiffToString,
  Dn as urlFromLocation
};
